import * as yup from "yup";
import { isValidPhone } from "lib/data/validators/phone";

export const validations = yup.object({
  name: yup.string().required("Nome é obrigatório"),

  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),

  phone: yup
    .string()
    .test("phone format", "Informe o telefone válido", (value) =>
      isValidPhone(value || "")
    )
    .required("Telefone é obrigatório"),
});
