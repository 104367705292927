import { ButtonClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiButtonOverrides: Partial<
  OverridesStyleRules<ButtonClassKey, {}>
> = {
  root: {
    textTransform: "none",
    fontWeight: Tokens.fontWeight.semibold,

    // Adds color transition, to the default values
    transition:
      "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
  },
  sizeLarge: {
    fontSize: Tokens.fontSize.sm,
  },
  sizeSmall: {
    fontSize: Tokens.fontSize.xxs,
  },
  contained: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: Tokens.interfaceColor.primary.pure,
      color: Tokens.neutralColor.light["1000"],
      opacity: 0.4,
    },
  },
  containedPrimary: {
    "&:hover": {
      backgroundColor: Tokens.interfaceColor.primary.pure,
    },
    "&:active": {
      backgroundColor: Tokens.interfaceColor.primary.dark,
    },
  },
  outlined: {
    "&.Mui-disabled": {
      color: Tokens.interfaceColor.primary.pure,
      borderColor: Tokens.interfaceColor.primary.pure,
      opacity: 0.4,
    },
    padding: "8px 16px",
  },
  outlinedPrimary: {
    "&:active": {
      backgroundColor: Tokens.neutralColor.light["800"],
      color: Tokens.interfaceColor.primary.dark,
    },
  },
  outlinedSecondary: {
    borderColor: Tokens.neutralColor.dark["200"],
    color: Tokens.neutralColor.dark["200"],
    "&:hover": {
      borderColor: Tokens.neutralColor.dark["200"],
      backgroundColor: Tokens.neutralColor.medium["700"],
    },
  },
  text: {
    color: Tokens.neutralColor.medium["400"],
    padding: 0,
    "&:hover": {
      color: Tokens.neutralColor.dark["300"],
      backgroundColor: "transparent",
    },
  },
};
