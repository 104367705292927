import { PaletteOptions } from "@mui/material";
import { Tokens } from "../../tokens/tokens";

export const palleteOverrides: PaletteOptions = {
  primary: {
    light: Tokens.interfaceColor.primary.light,
    main: Tokens.interfaceColor.primary.pure,
    dark: Tokens.interfaceColor.primary.dark,
    contrastText: "#fff",
  },
  info: {
    main: Tokens.interfaceColor.primary.pure,
    light: Tokens.interfaceColor.primary.light,
    dark: Tokens.interfaceColor.primary.dark,
  },
  warning: {
    light: Tokens.feedbackColor.warning.light,
    main: Tokens.feedbackColor.warning.pure,
    dark: Tokens.feedbackColor.warning.dark,
  },
  success: {
    light: Tokens.feedbackColor.success.light,
    main: Tokens.feedbackColor.success.pure,
    dark: Tokens.feedbackColor.success.dark,
  },
  error: {
    light: Tokens.feedbackColor.error.light,
    main: Tokens.feedbackColor.error.pure,
    dark: Tokens.feedbackColor.error.dark,
  },
  light: {
    light: Tokens.neutralColor.light[1000],
    main: Tokens.neutralColor.light[900],
    dark: Tokens.neutralColor.light[800],
  },
  medium: {
    light: Tokens.neutralColor.medium[700],
    main: Tokens.neutralColor.medium[600],
    medium: Tokens.neutralColor.medium[500],
    dark: Tokens.neutralColor.medium[400],
  },
  dark: {
    light: Tokens.neutralColor.dark[300],
    main: Tokens.neutralColor.dark[200],
    dark: Tokens.neutralColor.dark[100],
  },
};
