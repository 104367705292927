import { IconButtonClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiIconButtonOverrides: Partial<
  OverridesStyleRules<IconButtonClassKey, {}>
> = {
  root: {
    borderRadius: Tokens.borderRadius.medium,
    transition:
      "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

    "&:hover": {
      color: Tokens.interfaceColor.primary.pure,
    },
  },
  colorPrimary: {
    "&:hover": {
      color: Tokens.interfaceColor.primary.dark,
      backgroundColor: Tokens.interfaceColor.primary.light,
    },
  },
};
