import { Button } from "@mui/material";
import Form from "./form";
import { QueuedUpdateProvider } from "./queuedUpdateContext/provider";
import { Link } from "react-router-dom";

export function HomePage() {
  return (
    <QueuedUpdateProvider>
      {process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT" && (
        <Button>
          <Link to="/revenda">Revenda</Link>
        </Button>
      )}
      <Form />
    </QueuedUpdateProvider>
  );
}
