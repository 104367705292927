import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useStep } from "../../hooks/useStep";
import { PrevButton } from "components/prevButton";
import ProgressBar from "components/progressBar";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { PartnerFormProvider } from "./partnerFormContext/provider";

import { useUtmCookies } from "hooks/useCookie";
import { Step4 } from "./step4";
import { TagManagerService } from "services/tagManager";
import { getPartnerFormPageViewHash } from "services/tagManager/utils";

const PartnerForm: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const utmCookies = useUtmCookies({ params });

  const stepper = useStep();

  const hasPrev = stepper.current !== 0;

  useEffect(() => {
    goNextStepEvent(0);
  }, []);

  const goPrev = () => {
    stepper.prev();
  };

  const goNextStepEvent = (currentStep: number) => {
    TagManagerService.push(getPartnerFormPageViewHash(currentStep));
  };

  const goNext = () => {
    stepper.next({
      callback: goNextStepEvent,
    });
  };

  return (
    <PartnerFormProvider>
      <Box sx={{ mx: { xs: 1, md: 9 } }}>
        <Grid
          container
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
          columns={{ xs: 1, md: 12 }}
          display={stepper.current >= 3 ? "none" : "flex"}
        >
          <Grid item md={2}>
            {hasPrev && <PrevButton onClick={goPrev} />}
          </Grid>
          <Grid item xs={12} md={5}>
            <ProgressBar
              currentStepIndex={stepper.current}
              progressBarLength={3}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: { xs: 3, md: 6 },
          }}
        >
          <div data-cy="step1-partner-container" hidden={stepper.current !== 0}>
            <Step1 goNext={goNext} />
          </div>

          <div data-cy="step2-partner-container" hidden={stepper.current !== 1}>
            <Step2 goNext={goNext} />
          </div>

          <div data-cy="step3-partner-container" hidden={stepper.current !== 2}>
            <Step3 goNext={goNext} utmValues={utmCookies.utmValues} />
          </div>

          <div data-cy="step4-partner-container" hidden={stepper.current !== 3}>
            <Step4 />
          </div>
        </Box>
      </Box>
    </PartnerFormProvider>
  );
};

export default PartnerForm;
