import * as React from "react";
import { EnumWhereMeetCaju } from "services/caju/enums/enumWhereMeetCaju";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export interface WhereMeetCajuProps {
  error?: boolean;
  helperText?: string;
  onChange: (value: EnumWhereMeetCaju | "") => void;
  value: EnumWhereMeetCaju | "";
}

export function WhereMeetCaju(props: WhereMeetCajuProps) {
  function handleSelectChange(event: SelectChangeEvent<EnumWhereMeetCaju>) {
    props.onChange(event.target.value as EnumWhereMeetCaju);
  }

  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id="where-meet-caju-select-label">
        Como sua empresa conheceu a Caju?
      </InputLabel>

      <Select
        labelId="where-meet-caju-select-label"
        id="where-meet-caju-select"
        value={props.value}
        label="Como sua empresa conheceu a Caju?"
        onChange={handleSelectChange}
      >
        <MenuItem value="">Selecione um valor</MenuItem>

        {Object.values(EnumWhereMeetCaju).map((value) => (
          <MenuItem value={value} key={value} sx={{ whiteSpace: "unset" }}>
            {value}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
