import { SwitchClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiSwitchOverrides: Partial<
  OverridesStyleRules<SwitchClassKey, {}>
> = {
  track: {
    backgroundColor: Tokens.neutralColor.medium["600"],
    opacity: 1,
  },
  root: {
    "& $checked:not($disabled) + $track": {
      opacity: "1",
    },
    transition:
      "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
  },
  thumb: {
    color: Tokens.neutralColor.light["900"],
  },
};
