import { Card, CardHeader, Collapse, CardContent, Icon } from "@mui/material";
import React, { ReactElement } from "react";
import { Tokens } from "../../../../configs";

interface InfoCardProps {
  title: string;
  subheader?: string;
  children: ReactElement;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function InfoCard(props: InfoCardProps) {
  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        borderRadius: 1,
        border: "2px solid",
        borderColor: Tokens.neutralColor.medium["400"],
      }}
      elevation={0}
    >
      <CardHeader
        sx={{ p: 0 }}
        avatar={
          <i
            className="uil uil-video"
            style={{
              fontSize: 24,
              color: Tokens.neutralColor.dark["200"],
            }}
          />
        }
        title={props.title}
        titleTypographyProps={{
          fontSize: 16,
          fontWeight: 600,
        }}
        subheader={props.subheader}
        subheaderTypographyProps={{
          fontSize: 14,
          display: { lg: "none" },
          color: "dark.light",
        }}
        action={
          <Icon sx={{ display: { sx: "block", lg: "none" } }}>
            <i
              className={`uil ${
                props.open ? "uil-angle-up" : "uil-angle-down"
              }`}
              style={{
                fontSize: 24,
                color: Tokens.neutralColor.dark["100"],
              }}
            />
          </Icon>
        }
        onClick={() => props.setOpen(!props.open)}
      />
      <div>
        <Collapse
          sx={{ display: { sx: "block", lg: "none" } }}
          in={props.open}
          timeout={"auto"}
        >
          <CardContent
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              pl: 5,
            }}
          >
            {props.children}
          </CardContent>
        </Collapse>
        <CardContent
          sx={{
            p: 0,
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            gap: 2,
            pl: 5,
          }}
        >
          {props.children}
        </CardContent>
      </div>
    </Card>
  );
}
