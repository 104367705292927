import { AppBar, Toolbar, Typography, Link } from "@mui/material";
import React from "react";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { handleBackToWebsiteBtn } from "utils";

export const Footer = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        borderTop: "solid 1px",
        borderTopColor: "medium.main",
        py: 2.5,
        px: { md: 9 },
        bottom: 0,
        top: "auto",
        display: { md: "none" },
        backgroundColor: "light.light",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Link
          onClick={() =>
            handleBackToWebsiteBtn(
              "https://www.caju.com.br/contato",
              EnumGTMEvents.FORM_HEADER_HEADER_LINK_HELP_PAGE__CLICK
            )
          }
          sx={{
            fontWeight: 600,
            color: "dark.main",
            display: "flex",
            gap: 1,
            textDecoration: "none",
          }}
        >
          <Typography variant="subtitle2" sx={{ textDecoration: "underline" }}>
            Já usa Caju e precisa de ajuda?
          </Typography>
          <i
            className="uil uil-arrow-up-right"
            style={{
              fontSize: 24,
              display: "flex",
            }}
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
