import * as React from "react";
import { IHRValues, IPurhcasingAndFinancialValues } from "services/caju/enums/enumArea";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export interface AreaSelectProps {
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (value: string) => void;
  subarea: IPurhcasingAndFinancialValues | IHRValues| {};
}

export function SubareaSelect(props: AreaSelectProps) {
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id="subarea-select-label">Sua subárea*</InputLabel>
      <Select
        labelId="subarea-select-label"
        id="subarea-select"
        value={props.value}
        label="Sua subárea*"
        onChange={(evt) => {
          props.onChange(evt.target.value);
        }}
      >
        <MenuItem value={""}>Selecione uma subárea</MenuItem>

        {Object.entries(props.subarea).map(([key, value]) => (
          <MenuItem
            value={value.title}
            key={key}
            sx={{ justifyContent: "space-between" }}
          >
            {value.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
