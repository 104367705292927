export const commonEmails = [
  "gmail.com",
  "yahoo.com.br",
  "outlook.com",
  "hotmail.com",
  "bol.com.br",
  "ig.com.br",
  "uol.com.br",
  "terra.com.br",
  "oi.com.br",
  "globomail.com",
  "zipmail.com.br",
  "aol.com",
  "zoho.com",
  "protonmail.com",
  "yandex.com",
  "icloud.com",
  "r7.com",
  "locaweb.com.br",
  "exchange.microsoft.com",
  "godaddy.com",
  "vivaldi.net",
  "mail.com",
];
