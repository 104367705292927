import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import React from "react";
import { ModalWrapper } from "components/modalWrapper";

interface AwardsModalProps {
  open: boolean;
  onClose: () => void;
}

export const AwardsModal = (props: AwardsModalProps) => {
  return (
    <ModalWrapper
      title="O que é Caju Premiações?"
      subtitle="A solução para presentear com uma graninha extra no cartão, sem perder a segurança jurídica e sem misturar com o saldo de benefícios."
      open={props.open}
      handleClose={props.onClose}
    >
      <>
        <Typography variant="subtitle2" color="dark.dark" marginBottom={1}>
          O colaborador pode aproveitar:
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Ter mais liberdade para usar onde e quando quiser"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Pagar com cartão físico ou virtual"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Usar quando quiser, pois o saldo não expira"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </>
    </ModalWrapper>
  );
};
