import { IPartnerLeadDataDto } from "services/caju/interfaces/iPartnerLeadDataDto";
import { IStep2FormValues } from "./iStep2FormValues";
import { removeCNPJPunctuation } from "lib/data/formatters/CNPJ";

export function getDtoFromStep2PartnerFormValues(values: IStep2FormValues) {
  const result: IPartnerLeadDataDto = {
    document: removeCNPJPunctuation(values.company_CNPJ),
    name: values.company_name,
    partnersEmployees: values.employees,
    partnersPortfolioCnpj: values.partners_portfolio_CNPJ,
  };

  return result;
}
