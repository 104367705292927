import * as yup from "yup";

export const validations = yup.object({
  company: yup.string().required("Nome da empresa é obrigatório"),
  employeesNumber: yup
    .number()
    .required("Número de funcionários é obrigatório")
    .min(1, "O número mínimo de funcionarios é 1").max(200000, "Insira o número de colaboradores atuando no Brasil."),
  whereMeetCaju: yup.string(),
  cep: yup.string(),
  uf: yup.string().when('employeesNumber', (employeesNumber, schema) => 
    (Number(employeesNumber) >= 1000) ? schema.required("O UF é obrigatório") : schema
  ),
  website: yup.string().matches(
    /^((https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/?.*)?$/,
    'Insira uma URL válida'
  ),
  linkedin: yup.string().matches(
    /^(https?:\/\/(?:www\.)?linkedin\.com\/(?:in\/[a-zA-Z0-9_-]+|company\/[a-zA-Z0-9_-]+))\/?.*$/,
    'Insira uma URL válida do LinkedIn'
  ),
});
