import { AppBar, Toolbar, Typography, Link } from "@mui/material";
import { ReactComponent as CajuLogo } from "assets/images/logos/logo.svg";
import { ReactComponent as CajuLogoMobile } from "assets/images/logos/mobile-logo.svg";
import React from "react";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { handleBackToWebsiteBtn } from "utils";

export const Header = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        borderBottomWidth: { xs: 0, md: 1 },
        borderBottomStyle: "solid",
        borderBottomColor: "medium.main",
        py: 2.5,
        px: { md: 9 },
        top: 0,
        mt: 0,
        backgroundColor: "light.light",
        mb: { md: 5 },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          alignContent: "center",
          px: { md: 0 },
          minHeight: "auto",
        }}
      >
        <Link
          sx={{ display: { xs: "none", md: "block" } }}
          onClick={() =>
            handleBackToWebsiteBtn(
              "https://www.caju.com.br/",
              EnumGTMEvents.FORM_HEADER_CAJU_LOGO_CAJU__CLICK
            )
          }
        >
          <CajuLogo />
        </Link>

        <Link
          onClick={() =>
            handleBackToWebsiteBtn(
              "https://www.caju.com.br/",
              EnumGTMEvents.FORM_HEADER_CAJU_LOGO_CAJU__CLICK
            )
          }
          sx={{ display: { md: "none" } }}
        >
          <CajuLogoMobile />
        </Link>
        <Link
          onClick={() =>
            handleBackToWebsiteBtn(
              "https://www.caju.com.br/contato",
              EnumGTMEvents.FORM_HEADER_HEADER_LINK_HELP_PAGE__CLICK
            )
          }
          sx={{
            fontWeight: 600,
            color: "dark.main",
            display: { xs: "none", md: "flex" },
            gap: 1,
          }}
          underline="none"
          target="_blank"
        >
          <Typography variant="subtitle2" sx={{ textDecoration: "underline" }}>
            Já usa Caju e precisa de ajuda?
          </Typography>
          <i
            className="uil uil-arrow-up-right"
            style={{
              fontSize: 24,
              display: "flex",
            }}
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
