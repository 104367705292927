import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { RatingClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";

export const MuiRatingOverrides: Partial<
  OverridesStyleRules<RatingClassKey, {}>
> = {
  root: {
    color: Tokens.feedbackColor.warning.pure,
  },
};
