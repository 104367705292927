import { LinearProgressClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { Tokens } from "configs/tokens/tokens";

export const MuiLinearProgressOverrides: Partial<
  OverridesStyleRules<LinearProgressClassKey, {}>
> = {
  root: {
    backgroundColor: Tokens.neutralColor.light["800"],
    height: 8,
    borderRadius: 500,
  },
  bar: {
    borderRadius: 500,
  },
};
