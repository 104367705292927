import { StepConnectorClassKey } from "@mui/material";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiStepConnectorOverrides: Partial<
  OverridesStyleRules<StepConnectorClassKey, {}>
> = {
  root: {
    top: 20,
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: Tokens.neutralColor.medium["700"],
    },
    [`&.${stepConnectorClasses.vertical}`]: {
      marginLeft: 20,
      [`& .${stepConnectorClasses.line}`]: {
        minHeight: 16,
      },
    },
  },
};
