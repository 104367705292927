import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { AlertClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";

export const MuiAlertOverrides: Partial<
  OverridesStyleRules<AlertClassKey, {}>
> = {
  root: {
    marginTop: 0,
    "& $icon": {
      opacity: 1,
    },

    "& .MuiAlert-action": {
      alignItems: "baseline",

      "& .MuiIconButton-root:hover": {
        color: "inherit",
      },
    },
  },

  filledInfo: {
    fontWeight: 400,
    color: Tokens.interfaceColor.primary.dark,
    backgroundColor: Tokens.interfaceColor.primary.light,

    "& .MuiAlertTitle-root": {
      color: Tokens.interfaceColor.primary.dark,
    },
  },

  filledSuccess: {
    fontWeight: 400,
    color: Tokens.feedbackColor.success.dark,
    backgroundColor: Tokens.feedbackColor.success.light,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.success.dark,
    },
  },

  filledError: {
    fontWeight: 400,
    color: Tokens.feedbackColor.error.dark,
    backgroundColor: Tokens.feedbackColor.error.light,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.error.dark,
    },
  },

  filledWarning: {
    fontWeight: 400,
    color: Tokens.feedbackColor.warning.dark,
    backgroundColor: Tokens.feedbackColor.warning.light,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.warning.dark,
    },
  },

  outlinedInfo: {
    color: Tokens.neutralColor.dark["200"],
    borderColor: Tokens.neutralColor.medium["400"],
    borderWidth: 2,

    "& .MuiAlertTitle-root": {
      color: Tokens.neutralColor.dark["200"],
    },
    "& .MuiAlert-icon": {
      color: Tokens.neutralColor.dark["200"],
    },
  },

  outlinedSuccess: {
    color: Tokens.feedbackColor.success.dark,
    borderColor: Tokens.feedbackColor.success.pure,
    borderWidth: 2,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.success.dark,
    },
  },

  outlinedError: {
    color: Tokens.feedbackColor.error.dark,
    borderColor: Tokens.feedbackColor.error.pure,
    borderWidth: 2,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.error.dark,
    },
  },

  outlinedWarning: {
    color: Tokens.feedbackColor.warning.dark,
    borderColor: Tokens.feedbackColor.warning.pure,
    borderWidth: 2,

    "& .MuiAlertTitle-root": {
      color: Tokens.feedbackColor.warning.dark,
    },
  },
};
