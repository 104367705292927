import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { EnumProducts } from "services/caju/enums/enumProducts";
import { MultibenefitsModal } from "../multibenefitsModal";
import { CorpExpensesModal } from "../corpExpensesModal";
import { AwardsModal } from "../awardsModal";
import { CajuMaisModal } from "../cajuMaisModal";
import { CajuCiclosModal } from "../cajuCiclosModal";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { EnumLabelTag } from "services/tagManager/enums/enumLabelTag";

export interface PositionSelectProps {
  error?: boolean;
  helperText?: string | string[];
  values: EnumProducts[];
  onChange: (value: EnumProducts[] | "") => void;
  isWellhubAvailable: boolean;
  isCajuMaisAvailable: boolean;
}

export function ProductList(props: PositionSelectProps) {
  const [multiBenefitsModalVisible, setMultiBenefitsModalVisible] =
    React.useState(false);
  const [corpExpensesModalVisible, setCorpExpensesModalVisible] =
    React.useState(false);
  const [awardsModalVisible, setAwardsModalVisible] = React.useState(false);
  const [cajuMaisVisible, setCajuMaisVisible] = React.useState(false);
  const [cajuCiclosVisible, setCajuCiclosVisible] = React.useState(false);

  function handleProductClick(value: EnumProducts) {
    const hasValue = props.values.some((propValue) => propValue === value);
    const newValues = hasValue
      ? props.values.filter((propValue) => propValue !== value)
      : [...props.values, value];

    props.onChange(newValues);
  }

  function isChecked(value: EnumProducts) {
    return props.values.includes(value);
  }

  const handleModalOpenEvent = (labelTag: EnumLabelTag) => {
    TagManagerService.pushLabelTag(
      EnumGTMEvents.PRODUCT_INFO_PRODUCT_INFO_TAG_TAG_DETAIL__CLICK,
      {
        label_tag: labelTag,
      }
    );
  };

  return (
    <React.Fragment>
      <MultibenefitsModal
        open={multiBenefitsModalVisible}
        onClose={() => setMultiBenefitsModalVisible(false)}
      />
      <CorpExpensesModal
        open={corpExpensesModalVisible}
        onClose={() => setCorpExpensesModalVisible(false)}
      />
      <AwardsModal
        open={awardsModalVisible}
        onClose={() => setAwardsModalVisible(false)}
      />
      <CajuMaisModal
        open={cajuMaisVisible}
        onClose={() => setCajuMaisVisible(false)}
        isWellhubAvailable={props.isWellhubAvailable}
      />
      <CajuCiclosModal
        open={cajuCiclosVisible}
        onClose={() => setCajuCiclosVisible(false)}
      />

      <FormControl fullWidth error={props.error}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <ListItem
            sx={{
              borderWidth: isChecked(EnumProducts.MULTIBENEFITS) ? 2 : 1,
              borderColor: isChecked(EnumProducts.MULTIBENEFITS)
                ? "primary.main"
                : "medium.main",
              borderStyle: "solid",
              borderRadius: 0.5,
            }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleProductClick(EnumProducts.MULTIBENEFITS)}
              dense
              sx={{
                p: 2,
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <Checkbox
                  edge="start"
                  checked={isChecked(EnumProducts.MULTIBENEFITS)}
                  tabIndex={-1}
                  disableRipple
                  value={EnumProducts.MULTIBENEFITS}
                  inputProps={{ "aria-labelledby": EnumProducts.MULTIBENEFITS }}
                />
              </ListItemIcon>
              <Box>
                <ListItemText
                  id={EnumProducts.MULTIBENEFITS}
                  primary={"Cartão multibenefícios para colaboradores"}
                  primaryTypographyProps={{
                    color: "dark.light",
                    fontSize: 16,
                    mb: 1,
                  }}
                />

                <Chip
                  size="small"
                  label="Caju Multibenefícios"
                  icon={<i className="uil uil-question-circle" />}
                  clickable
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleModalOpenEvent(EnumLabelTag.TAG_MULTIBENEFITS);
                    setMultiBenefitsModalVisible(true);
                  }}
                />
              </Box>
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              borderWidth: isChecked(EnumProducts.CORP_EXPENSES) ? 2 : 1,
              borderColor: isChecked(EnumProducts.CORP_EXPENSES)
                ? "primary.main"
                : "medium.main",
              borderStyle: "solid",
              borderRadius: 0.5,
            }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleProductClick(EnumProducts.CORP_EXPENSES)}
              dense
              sx={{
                p: 2,
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <Checkbox
                  edge="start"
                  checked={isChecked(EnumProducts.CORP_EXPENSES)}
                  tabIndex={-1}
                  disableRipple
                  value={EnumProducts.CORP_EXPENSES}
                  inputProps={{ "aria-labelledby": EnumProducts.CORP_EXPENSES }}
                />
              </ListItemIcon>
              <Box>
                <ListItemText
                  id={EnumProducts.CORP_EXPENSES}
                  primary={"Controle e gestão fácil de despesas corporativas"}
                  primaryTypographyProps={{
                    color: "dark.light",
                    fontSize: 16,
                    mb: 1,
                  }}
                />
                <Chip
                  size="small"
                  label="Caju Despesas"
                  icon={<i className="uil uil-question-circle" />}
                  clickable
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleModalOpenEvent(EnumLabelTag.TAG_CORPEXPENSES);
                    setCorpExpensesModalVisible(true);
                  }}
                />
              </Box>
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              borderWidth: isChecked(EnumProducts.AWARDS) ? 2 : 1,
              borderColor: isChecked(EnumProducts.AWARDS)
                ? "primary.main"
                : "medium.main",
              borderStyle: "solid",
              borderRadius: 0.5,
            }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleProductClick(EnumProducts.AWARDS)}
              dense
              sx={{
                p: 2,
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <Checkbox
                  edge="start"
                  checked={isChecked(EnumProducts.AWARDS)}
                  tabIndex={-1}
                  disableRipple
                  value={EnumProducts.AWARDS}
                  inputProps={{ "aria-labelledby": EnumProducts.AWARDS }}
                />
              </ListItemIcon>
              <Box>
                <ListItemText
                  id={EnumProducts.AWARDS}
                  primary={"Premiações para o seu time"}
                  primaryTypographyProps={{
                    color: "dark.light",
                    fontSize: 16,
                    mb: 1,
                  }}
                />

                <Chip
                  size="small"
                  label="Caju Premiações"
                  icon={<i className="uil uil-question-circle" />}
                  clickable
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleModalOpenEvent(EnumLabelTag.TAG_AWARDS);
                    setAwardsModalVisible(true);
                  }}
                />
              </Box>
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              borderWidth: isChecked(EnumProducts.CAJU_CICLOS) ? 2 : 1,
              borderColor: isChecked(EnumProducts.CAJU_CICLOS)
                ? "primary.main"
                : "medium.main",
              borderStyle: "solid",
              borderRadius: 0.5,
            }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleProductClick(EnumProducts.CAJU_CICLOS)}
              dense
              sx={{
                p: 2,
                pr: 12,
              }}
            >
              <>
                <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                  <Checkbox
                    edge="start"
                    checked={isChecked(EnumProducts.CAJU_CICLOS)}
                    tabIndex={-1}
                    disableRipple
                    value={EnumProducts.CAJU_CICLOS}
                    inputProps={{
                      "aria-labelledby": EnumProducts.CAJU_CICLOS,
                    }}
                  />
                </ListItemIcon>
                <Box>
                  <ListItemText
                    id={EnumProducts.CAJU_CICLOS}
                    primary={"Admissão e gestão de dados do colaborador"}
                    primaryTypographyProps={{
                      color: "dark.light",
                      fontSize: 16,
                      mb: 1,
                    }}
                  />
                  <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    <Chip
                      size="small"
                      label="Caju Ciclos"
                      icon={<i className="uil uil-question-circle" />}
                      clickable
                      onClick={(evt) => {
                        evt.stopPropagation();
                        handleModalOpenEvent(EnumLabelTag.TAG_CAJUCICLOS);
                        setCajuCiclosVisible(true);
                      }}
                    />
                  </Box>
                </Box>
              </>
            </ListItemButton>
            <ListItemSecondaryAction
              sx={{
                right: 0,
                backgroundColor: "primary.main",
                p: 1,
                borderRadius: "4px 0px 0px 4px",
                color: "light.light",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Novidade!
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem
            sx={{
              borderWidth: isChecked(EnumProducts.CAJU_MAIS) ? 2 : 1,
              borderColor: isChecked(EnumProducts.CAJU_MAIS)
                ? "primary.main"
                : "medium.main",
              borderStyle: "solid",
              borderRadius: 0.5,
            }}
            disablePadding
          >
            <ListItemButton
              disabled={!props.isCajuMaisAvailable}
              onClick={() => handleProductClick(EnumProducts.CAJU_MAIS)}
              dense
              sx={{
                p: 2,
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto", marginRight: 1 }}>
                <Checkbox
                  edge="start"
                  checked={isChecked(EnumProducts.CAJU_MAIS)}
                  tabIndex={-1}
                  disableRipple
                  value={EnumProducts.CAJU_MAIS}
                  inputProps={{ "aria-labelledby": EnumProducts.CAJU_MAIS }}
                />
              </ListItemIcon>
              <Box>
                <ListItemText
                  id={EnumProducts.CAJU_MAIS}
                  primary={
                    "Solução integrada de bem-estar para os seus colaboradores"
                  }
                  primaryTypographyProps={{
                    color: "dark.light",
                    fontSize: 16,
                    mb: 1,
                  }}
                />
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                  <Chip
                    size="small"
                    label="Caju Mais"
                    icon={<i className="uil uil-question-circle" />}
                    clickable
                    onClick={(evt) => {
                      evt.stopPropagation();
                      handleModalOpenEvent(EnumLabelTag.TAG_CAJUMAIS);
                      setCajuMaisVisible(true);
                    }}
                  />
                  <Chip
                    size="small"
                    label="Wellhub"
                    sx={{
                      display: props.isWellhubAvailable ? "block" : "none",
                    }}
                  />
                  <Chip size="small" label="Conexa Saúde" />
                  <Chip size="small" label="Optum" />
                  <Chip size="small" label="SAT" />
                </Box>
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
      </FormControl>
    </React.Fragment>
  );
}
