import * as React from "react";
import { AreaFields } from "services/caju/enums/enumArea";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export interface AreaSelectProps {
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (value: string) => void;
  setSubareaField: React.Dispatch<React.SetStateAction<string>>;
}

export function AreaSelect(props: AreaSelectProps) {
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id="area-select-label">Sua área*</InputLabel>
      <Select
        labelId="area-select-label"
        id="area-select"
        value={props.value}
        label="Sua área*"
        onChange={(evt) => {
          props.onChange(evt.target.value);
        }}
      >
        <MenuItem value={""}>Selecione um valor</MenuItem>

        {Object.entries(AreaFields).map(([key, value]) => (
          <MenuItem
            value={value.title}
            key={key}
            sx={{ justifyContent: "space-between" }}
            onClick={() =>
              props.setSubareaField(
                value.hasOwnProperty("subarea") ? key : ""
              )
            }
          >
            {value.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
