import { CircularProgressClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiCircularProgressOverrides: Partial<
  OverridesStyleRules<CircularProgressClassKey, {}>
> = {
  root: {
    color: Tokens.neutralColor.light["1000"],
  },
};
