import { EnumCajuInterest } from "services/caju/enums/enumCajuInterest";
import { IUpdateLeadDataDto } from "../../../services/caju/interfaces/iUpdateLeadDataDto";

export function getDtoFromStep4FormValues(
  leadId: string,
  caju_interest__c: EnumCajuInterest,
  id: string
) {
  const result: IUpdateLeadDataDto = {
    externalId: leadId,
    cajuInterest: caju_interest__c,
    expectedLeadId: id,
    step: "4",
    isLead: true,
  };

  return result;
}
