export enum EnumCalendlyUrls {
  CAJU_CICLOS__ALL = "https://calendly.com/carolina-srocha/apresentacao-cajuciclos",

  CAJU_DESPESAS__SMALL = "https://calendly.com/raphael-wqm/conhecendo-caju-despesas-",
  CAJU_DESPESAS__MEDIUM = "https://calendly.com/raphael-wqm/conhecendo-caju-despesas",
  CAJU_DESPESAS__LARGE = "https://calendly.com/d/ykq-t9t-37v/caju-despesas",

  INTRO_CAJU__CAJU_BENEFICIOS__SMALL = "https://calendly.com/intro_caju_/papo-rapido-caju-beneficios-pc",
  INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM = "https://calendly.com/intro_caju_/papo-rapido-caju-beneficios-mc",
  INTRO_CAJU__CAJU_BENEFICIOS__LARGE = "https://calendly.com/intro_caju_/papo-rapido-caju-beneficios-gc",

  INTRO_CAJU__INTRODUCAO__SMALL = "https://calendly.com/intro_caju_/introducao-caju-pc",

  MASSIVO__ALL = "https://calendly.com/mesa-aberta-caju/conhecendo-caju",
}

export enum EnumMultipleWithCiclos {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumMultipleWithMultiWithoutCiclos {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__INTRODUCAO__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumMultipleWithoutMultiWithoutCiclos {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__INTRODUCAO__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumOnlyMulti {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__INTRODUCAO__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumOnlyRewards {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__INTRODUCAO__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumOnlyCajuMais {
  "1-19" = EnumCalendlyUrls.MASSIVO__ALL,
  "20-99" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__SMALL,
  "100+" = EnumCalendlyUrls.INTRO_CAJU__CAJU_BENEFICIOS__MEDIUM,
}

export enum EnumOnlyDespesas {
  "1-9" = EnumCalendlyUrls.CAJU_DESPESAS__SMALL,
  "10-99" = EnumCalendlyUrls.CAJU_DESPESAS__MEDIUM,
  "100+" = EnumCalendlyUrls.CAJU_DESPESAS__LARGE,
}

export enum EnumOnlyCiclos {
  "1+" = EnumCalendlyUrls.CAJU_CICLOS__ALL,
}
