import { Avatar } from "@mui/material";
import * as React from "react";

import { StepIconProps } from "@mui/material/StepIcon";
import { Tokens } from "configs";

export function StepLabelIcon(props: StepIconProps) {
  const isActiveIcon = props.active && !props.error;
  return (
    <Avatar
      sx={{
        borderStyle: "solid",
        borderWidth: isActiveIcon ? 3 : 1,
        backgroundColor: "transparent",
        borderColor: isActiveIcon
          ? Tokens.categoryColor.health.pure
          : Tokens.neutralColor.medium["700"],
        color: isActiveIcon
          ? Tokens.categoryColor.health.pure
          : Tokens.neutralColor.medium["400"],
        fontWeight: 600,
        fontSize: 16,
      }}
    >
      {props.icon}
    </Avatar>
  );
}
