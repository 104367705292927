import {
  Card,
  Box,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import * as React from "react";

import DownloadCardImage from "assets/images/confirmacao_agendamento.png";
import { EnumProducts } from "services/caju/enums/enumProducts";
import { downloadPresentation } from "../utils";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { handleBackToWebsiteBtn } from "utils";

interface PresentationCardProps {
  employeesNumber?: number | "";
  products?: EnumProducts[];
}

export function PresentationCard(props: PresentationCardProps) {
  const handleDownloadPresentation = () => {
    if (props.employeesNumber && props.products) {
      downloadPresentation(props.products, props.employeesNumber);
      TagManagerService.push(
        EnumGTMEvents.CONFIRMATION_INFO_PRESENTATION_BTN_DOWNLOAD__CLICK
      );
    } else {
      return;
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#f3f3f3",
        p: { xs: 2.5, md: 2 },
        borderRadius: 0.5,
        width: { xs: "100vw", md: "100%" },
        maxWidth: 800,
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "flex-start",
          gap: 4,
        }}
      >
        <CardMedia
          component="img"
          image={DownloadCardImage}
          sx={{
            objectFit: "contain",
            width: { xs: "auto", md: "300px" },
            height: { xs: "140px", md: "auto" },
          }}
        />
        <Box>
          <CardContent sx={{ p: 0 }}>
            <Typography variant="subtitle2" pb={1}>
              Até chegar nossa conversa, que tal baixar a apresentação comercial
              da Caju?
            </Typography>
            <Typography variant="body2">
              Assim você já se prepara e anota todas as dúvidas para a nossa
              reunião
            </Typography>
          </CardContent>
          <CardActions
            sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, mt: 3 }}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDownloadPresentation}
              startIcon={
                <i
                  className="uil uil-import"
                  style={{
                    fontSize: 20,
                    display: "flex",
                  }}
                />
              }
            >
              Baixar apresentação
            </Button>
            <Button
              variant="text"
              sx={{ color: "dark.main" }}
              startIcon={
                <i
                  className="uil uil-arrow-left"
                  style={{
                    fontSize: 24,
                    display: "flex",
                  }}
                />
              }
              onClick={() =>
                handleBackToWebsiteBtn(
                  "https://www.caju.com.br",
                  EnumGTMEvents.LAST_PAGE_BACKWARD_BTN_HOME_PAGE__CLICK
                )
              }
            >
              <Typography
                sx={{
                  textDecoration: "underline",
                  display: { xs: "none", md: "block" },
                }}
                variant="subtitle2"
              >
                Voltar para o site
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: 14,
                  textDecoration: "underline",
                  display: { md: "none" },
                }}
              >
                Site
              </Typography>
            </Button>
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
}
