import { Button, ButtonProps } from "@mui/material";
import * as React from "react";

export interface NextButtonProps extends ButtonProps {}

export function NextButton(props: NextButtonProps) {
  return (
    <Button
      variant="contained"
      sx={{
        py: 1.5,
        px: 3,
        width: { xs: "100%", md: "auto" },
        fontSize: 16,
        mt: { xs: 2, md: 4 },
        mb: 3,
      }}
      endIcon={
        <i
          className="uil uil-arrow-right"
          style={{
            fontSize: 24,
            display: "flex",
          }}
        />
      }
      disableElevation
      {...props}
    >
      Continuar
    </Button>
  );
}
