import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import { IProgressBar } from "./IProgressBar";

const ProgressBar = ({ currentStepIndex, progressBarLength }: IProgressBar) => {
  function progressBarValue() {
    return ((currentStepIndex + 1) * 100) / progressBarLength;
  }
  return (
    <Box
      sx={{
        display: currentStepIndex < progressBarLength ? "block" : "none",
      }}
    >
      <Typography
        sx={{ mb: "0.5rem", display: { xs: "none", md: "block" } }}
        color="dark"
      >
        Etapa {currentStepIndex + 1} de {progressBarLength}
      </Typography>
      <LinearProgress variant="determinate" value={progressBarValue()} />
    </Box>
  );
};

export default ProgressBar;
