import * as React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

export interface SelectFieldProps {
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  label: string;
  id: string;
  children: React.ReactNode;
}

export function SelectField(props: SelectFieldProps) {
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id={`${props.id}-label`}>{props.label}</InputLabel>
      <Select
        labelId={`${props.id}-label`}
        id={props.id}
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <MenuItem value={""}>Selecione um valor</MenuItem>

        {props.children}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
