import * as React from "react";
import { PartnerFormContext } from "./context";
import { AxiosError } from "axios";
import { IPartnerLeadDataDto } from "services/caju/interfaces/iPartnerLeadDataDto";
import { LeadService } from "services/caju";

export interface PartnerFormProviderProps {
  children?: any;
}

export function PartnerFormProvider(props: PartnerFormProviderProps) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<AxiosError>();
  const [partnerFormValues, setPartnerFormValues] =
    React.useState<IPartnerLeadDataDto>({
      partnerOrigin: "Formulário",
      stageName: "Novo",
      recordType: { name: "Revenda" },
    });

  const updateFormValues = React.useCallback((data: IPartnerLeadDataDto) => {
    setPartnerFormValues((currValues: IPartnerLeadDataDto) => {
      return { ...data, ...currValues };
    });
  }, []);

  const push = React.useCallback(
    async (data: IPartnerLeadDataDto) => {
      try {
        setLoading(true);
        const formValues = {
          ...data,
          ...partnerFormValues,
        };

        updateFormValues(data);

        await LeadService.postOpportunity(formValues);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [updateFormValues, partnerFormValues]
  );

  return (
    <PartnerFormContext.Provider
      value={{ loading, error, updateFormValues, push }}
    >
      {props.children}
    </PartnerFormContext.Provider>
  );
}
