import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "components/ErrorBoundaryFallback";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { RouterProvider } from "react-router-dom";
import router from "routes";

function App() {
  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </ErrorBoundary>
  );
}

export default App;
