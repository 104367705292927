import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import React from "react";
import { ModalWrapper } from "components/modalWrapper";

export interface CorpExpensesModalProps {
  open: boolean;
  onClose: () => void;
}

export const CorpExpensesModal = (props: CorpExpensesModalProps) => {
  return (
    <ModalWrapper
      title="O que é Caju Despesas?"
      subtitle="Uma plataforma, um cartão, fluxos otimizados, informações
              detalhadas e poucos cliques para ter a gestão de despesas em dia."
      open={props.open}
      handleClose={props.onClose}
    >
      <>
        <Typography variant="subtitle2" color="dark.dark" marginBottom={1}>
          Funcionalidades e benefícios:
        </Typography>
        <Grid container spacing={{ xs: 0, md: 7 }} columns={{ xs: 1, md: 2 }}>
          <Grid item xs={1}>
            <List sx={{ p: 0 }}>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Aprovação ou reprovação de gastos"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Visualização de extrato detalhado"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Edição da despesa pelo administrador"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={1}>
            <List sx={{ p: 0 }}>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Cartão virtual e físico"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Perfil de acesso exclusivo"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </>
    </ModalWrapper>
  );
};
