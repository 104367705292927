import Cookies from "js-cookie";
import { IUtmData } from "pages/home/step1/useStep1Form/iStep1FormValues";
import React from "react";

export interface UseUtmCookiesProps {
  params: URLSearchParams;
}

const utms = [
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export const useUtmCookies = (props: UseUtmCookiesProps) => {
  const [utmValues, setUtmValues] = React.useState<IUtmData | undefined>();
  const params = props.params;

  const setCookies = React.useCallback(() => {
    utms.forEach((utm: string) => {
      Cookies.set(utm, params.get(utm) || '', { expires: 365 });
    });
  }, [params]);

  React.useEffect(() => {
    if (!utmValues) {
      setCookies();
      setUtmValues(
        Object.fromEntries(utms.map((utm) => [utm, Cookies.get(utm)]))
      );
    }
  }, [setCookies, utmValues]);

  return { utmValues: utmValues };
};
