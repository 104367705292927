import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React from "react";

interface InterestOptionItemProps {
  title: string;
  description: string;
  onClick: () => void;
}

export function InterestOptionItem(props: InterestOptionItemProps) {
  return (
    <ListItem
      sx={{
        borderWidth: 1,
        borderColor: "medium.main",
        borderStyle: "solid",
        borderRadius: 0.5,
      }}
      disablePadding
    >
      <ListItemButton sx={{ p: 2, gap: 4.75 }} onClick={() => props.onClick()}>
        <ListItemText
          primary={props.title}
          primaryTypographyProps={{
            fontWeight: 600,
            pb: 1,
          }}
          secondary={props.description}
          secondaryTypographyProps={{
            fontSize: 16,
          }}
        />
        <ListItemIcon sx={{display: { xs: 'none', md: 'block' }}}>
          <i
            className="uil uil-arrow-right"
            style={{
              fontSize: 32,
            }}
          />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}
