export const Variables = {
  signupLink: "https://empresa.caju.com.br/classic/#/cadastro?seller_id=665bf650-48ff-4edf-b47f-d72860074c1f",

  presentationLinks: {
    onlyExpenses: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_despesas.pdf",
    onlyCajuCiclos: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_ciclos.pdf",
    onlyCajuMaisMoreThan50: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_mais_3.pdf",
    onlyCajuMaisBetween49and10: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_mais_2.pdf",
    onlyCajuMaisLessThan9: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_mais_1.pdf",
    anyMoreThan50: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_2.pdf",
    anyLessThan49: "https://caju-assets.s3.sa-east-1.amazonaws.com/apresentacao_caju_1.pdf",
  },
};
