import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { AxiosError } from "axios";
import { Tokens } from "configs";
import * as React from "react";

import { ReactComponent as ErrorIcon } from "assets/images/icons/error_icon.svg";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { handleBackToWebsiteBtn } from "utils";

interface loadingModalProps {
  open: boolean;
  hasError?: AxiosError;
}

export function LoadingModal(props: loadingModalProps) {
  React.useEffect(() => {
    if (props.hasError && props.open) {
      TagManagerService.push(EnumGTMEvents.ERROR_MSG__VIEW);
    }
  }, [props.open, props.hasError]);

  return (
    <Modal
      open={props.open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: { xs: "flex-end", md: "center" },
      }}
      disableAutoFocus
      slotProps={{
        backdrop: {
          sx: { display: { xs: "none", md: "flex" } },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          p: { xs: 3, md: 8 },
          height: { xs: "calc(100vh - 100px)", md: "auto" },
          width: { xs: "100%", md: 850 },
          backgroundColor: Tokens.neutralColor.light["1000"],
          borderRadius: { md: 0.5 },
          borderTopWidth: { xs: 1, md: 0 },
          borderTopStyle: "solid",
          borderTopColor: Tokens.neutralColor.medium["600"],
        }}
      >
        {props.hasError && (
          <>
            <ErrorIcon />
            <Typography variant="subtitle1" textAlign="center" maxWidth="sm">
              Algo deu errado
            </Typography>
            <Typography variant="body1" textAlign="center" maxWidth="sm">
              Desculpe, não conseguimos salvar seus dados. Por favor, retorne e
              tente novamente mais tarde.
            </Typography>
            <Button
              variant="outlined"
              onClick={() =>
                handleBackToWebsiteBtn(
                  "https://www.caju.com.br",
                  EnumGTMEvents.ERROR_MSG_BACKWARD_BTN_HOME_PAGE__CLICK
                )
              }
            >
              Voltar para o site
            </Button>
          </>
        )}
        {!props.hasError && (
          <>
            <CircularProgress
              size={80}
              thickness={4}
              sx={{
                color: Tokens.neutralColor.medium["600"],
                animationDuration: "3.5s",
              }}
            />
            <Typography variant="subtitle1" textAlign="center" maxWidth="sm">
              Quase lá...
            </Typography>
            <Typography variant="body1" textAlign="center" maxWidth="sm">
              Aguarde, estamos salvando seus dados antes de seguir para a página
              de cadastro. Por favor, não feche essa janela.
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
}
