interface IAreaFields {
  [key: string]: {title: string, subarea?: IPurhcasingAndFinancialValues | IHRValues}
}

export interface IHRValues {
  BUSINESS_PARTNER: { title: string }
  PERSONAL_DEPARTMENT: { title: string }
  EMPLOYER_BRANDING: { title: string }
  TRAINING_AND_DEVELOPMENT: { title: string }
  RECRUITMENT_AND_SELECTION: { title: string }
}

export interface IPurhcasingAndFinancialValues {
  TREASURY: { title: string }
  ACCOUNTING: { title: string }
  PLANNING: { title: string }
}

export const AreaFields: IAreaFields = {
  HR: {
    title: "RH / Gente e Gestão", 
    subarea: {
      BUSINESS_PARTNER: {title: "Business Partner"},
      EMPLOYER_BRANDING: {title: "Employer Branding"},
      PERSONAL_DEPARTMENT: {title: "Departamento Pessoal e Administrativo"},
      RECRUITMENT_AND_SELECTION: {title: "Recrutamento e Seleção"},
      TRAINING_AND_DEVELOPMENT: {title: "Treinamento e Desenvolvimento"},
    }
  },
  LEGAL: {title: "Jurídico / Legal"},
  PURCHASING_AND_FINANCIAL: {
    title: "Compras / Financeiro", 
    subarea: {
      TREASURY: {title: "Tesouraria"},
      ACCOUNTING: {title: "Contabilidade/Contas a Pagar"},
      PLANNING: {title: "Planejamento"},
    }
  },
  OTHER: {title: "Outros"},
}