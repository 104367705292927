import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { AvatarClassKey } from "@mui/material";

export const MuiAvatarOverrides: Partial<
  OverridesStyleRules<AvatarClassKey, {}>
> = {
  square: {
    borderRadius: 1,
    padding: 4,
    height: 24,
    width: 24,
  },
};
