import { Box, Typography, Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

import { ReactComponent as ConfirmacaoAgendamentoImage } from "assets/images/confirmacao_agendamento_icon.svg";

import { PresentationCard } from "../step6/PresentationCard";
import { Tokens } from "configs";
import { EnumProducts } from "services/caju/enums/enumProducts";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { ReactComponent as ErrorIcon } from "assets/images/icons/error_icon.svg";

interface Step6Props {
  isLoading?: boolean;
  employeesNumber?: number | "";
  products?: EnumProducts[];
}

export function Step7(props: Step6Props) {
  const queueContext = React.useContext(QueuedUpdateContext);

  const contextSuccess = !queueContext.loading && !queueContext.error;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 3,
        pb: 3,
      }}
      maxWidth="lg"
      className="step-7-container"
    >
      {contextSuccess  && (
        <>
          <Box>
            <ConfirmacaoAgendamentoImage />
          </Box>

          <Typography
            variant="subtitle1"
            textAlign="center"
            maxWidth="sm"
            sx={{ fontSize: { md: 24 }, fontWeight: { md: 700 }, mb: 1, lineHeight: '120%' }}
          >
            Dados enviados com sucesso. <br />
            Entraremos em contato com você.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Fique atento ao e-mail e telefone que você nos informou. Sua empresa está no caminho certo para trazer mais sabor à vida corporativa.
          </Typography>
        </>
      )}

      {queueContext.loading && (
        <>
          <CircularProgress
            size={80}
            thickness={4}
            sx={{
              color: Tokens.neutralColor.medium["600"],
              animationDuration: "3.5s",
              mb: 3,
            }}
          />
          <Typography variant="h6" textAlign="center" maxWidth="sm">
            Quase lá...
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Aguarde, estamos enviando seus dados.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Não feche essa janela até receber a confirmação.
          </Typography>
        </>
      )}

      {queueContext.error && (
        <>
          <ErrorIcon />
          <Typography variant="h6" textAlign="center" maxWidth="sm">
            Algo deu errado
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Desculpe, não conseguimos salvar seus dados.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Por favor, retorne e tente novamente mais tarde.
          </Typography>
        </>
      )}

      <Box sx={{mt:15.5}}>
        <PresentationCard
          employeesNumber={props?.employeesNumber}
          products={props?.products}
        />
      </Box>
    </Container>
  );
}
