import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import React from "react";
import { ModalWrapper } from "components/modalWrapper";

export interface CajuCiclosModalProps {
  open: boolean;
  onClose: () => void;
}

export const CajuCiclosModal = (props: CajuCiclosModalProps) => {
  return (
    <ModalWrapper
      title="O que é Caju Ciclos"
      subtitle="A plataforma ideal para empresas que querem centralizar a jornada do colaborador em um só lugar"
      open={props.open}
      handleClose={props.onClose}
    >
      <>
        <Typography variant="subtitle2" color="dark.dark" marginBottom={1}>
          Funcionalidades e benefícios:
        </Typography>
        <Grid container>
          <Grid item>
            <List sx={{ p: 0 }}>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Admissão digital facilitada"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Gestão de dados do colaborador"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <i
                    className="uil uil-check"
                    style={{
                      fontSize: 24,
                      color: "#E80537",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Relatórios personalizados para análises estratégicas"
                  primaryTypographyProps={{
                    fontSize: 14,
                    color: "dark.light",
                  }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </>
    </ModalWrapper>
  );
};
