export enum EnumPartnerFormEvents {
  PARTNERS_FORM__CONTACT_INFO__VIEW = "001/047-002.000:000;002",
  PARTNERS_FORM__CONTACT_INFO__FORM_LINK__POLITICA_DE_PRIVACIDADE__CLICK = "001/047-002.014:165;001",
  PARTNERS_FORM__CONTACT_INFO__FORM_BTN__CONTINUE_CLICK = "001/047-002.004:004;001",
  PARTNERS_FORM__COMPANY_INFO__VIEW = "001/047-005.000:000;002",
  PARTNERS_FORM__COMPANY_INFO__FORM_BTN__CONTINUE_CLICK = "001/047-005.004:004;001",
  PARTNERS_FORM__PORTFOLIO_INFO__VIEW = "001/047-127.000:000;002",
  PARTNERS_FORM__PORTFOLIO_INFO__FORM_BTN__CONTINUE__CLICK = "001/047-127.004:004;001",
  PARTNERS_FORM__CONFIRMATION_INFO__VIEW = "001/047-009.000:000;002",
}
