import * as React from "react";
import { EnumContactJob } from "services/caju/enums/enumContactJob";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export interface PositionSelectProps {
  error?: boolean;
  helperText?: string;
  value: string;
  onChange: (value: string) => void;
  disabled: boolean
}

export function PositionSelect(props: PositionSelectProps) {
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id="position-select-label">Seu cargo*</InputLabel>
      <Select
        labelId="position-select-label"
        id="position-select"
        value={props.value}
        label="Seu cargo*"
        onChange={(evt) => {
          props.onChange(evt.target.value as EnumContactJob);
        }}
        disabled={props.disabled}
      >
        <MenuItem value={""}>Selecione um valor</MenuItem>
        <MenuItem value={EnumContactJob.CLEVEL_BOARD}>
          {EnumContactJob.CLEVEL_BOARD}
        </MenuItem>
        <MenuItem value={EnumContactJob.MANAGER_OR_COORDINATOR}>
          {EnumContactJob.MANAGER_OR_COORDINATOR}
        </MenuItem>
        <MenuItem value={EnumContactJob.ANALYST}>
          {EnumContactJob.ANALYST}
        </MenuItem>
        <MenuItem value={EnumContactJob.INTERN}>
          {EnumContactJob.INTERN}
        </MenuItem>
        <MenuItem value={EnumContactJob.OTHER}>{EnumContactJob.OTHER}</MenuItem>
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
