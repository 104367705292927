import { Box, Button, Grid, MenuItem, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { FormWrapper } from "components/formWrapper";
import { useStep3Form } from "./useStep3Form";
import { SelectField } from "components/SelectField";
import { EnumPartnerSegment } from "services/caju/enums/partnerForm/enumPartnerSegment";
import { EnumPartnerSegmentPortfolio } from "services/caju/enums/partnerForm/enumPartnerSegmentPortfolio";
import { EnumPartnerAndClientEmployees } from "services/caju/enums/partnerForm/enumClientEmployees";
import { EnumPartnerReason } from "services/caju/enums/partnerForm/enumPartnerReason";
import { PartnerFormContext } from "../partnerFormContext/context";
import React from "react";
import { getDtoFromStep3PartnerFormValues } from "./useStep3Form/helper";
import { IUtmData } from "./useStep3Form/iStep3FormValues";
import { TagManagerService } from "services/tagManager";
import { EnumPartnerFormEvents } from "services/tagManager/enums/enumPartnerFormEvents";
import { EnumProductsToSell } from "services/caju/enums/partnerForm/enumProductsToSell";
import { MultipleSelectField } from "components/MultipleSelectField";

interface Step3Props {
  goNext: () => void;
  utmValues: IUtmData | undefined;
}

export const Step3 = (props: Step3Props) => {
  const partnerFormContext = React.useContext(PartnerFormContext);

  const form = useStep3Form({
    onSubmit: (values) => {
      const data = getDtoFromStep3PartnerFormValues({
        ...values,
        utm_data: props.utmValues,
      });
      partnerFormContext.push(data);
      handleSubmitEvent();
      props.goNext();
    },
  });

  const handleSubmitEvent = () => {
    TagManagerService.push(
      EnumPartnerFormEvents.PARTNERS_FORM__PORTFOLIO_INFO__FORM_BTN__CONTINUE__CLICK
    );
  };

  const handleProductsToSellChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value
    form.setFieldValue("products_to_sell__c", typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormWrapper>
      <>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
        >
          <Typography variant="subtitle1">
            Sobre a sua carteira de clientes
          </Typography>
        </Grid>

        <form data-cy="step3-partner-form" onSubmit={form.handleSubmit}>
          <Grid container gap={{ xs: 2, md: 4 }}>
            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label="Qual o segmento da sua carteira de clientes?"
                id="partners_segment_portfolio"
                error={
                  form.touched.partners_segment_portfolio &&
                  !!form.errors.partners_segment_portfolio
                }
                helperText={
                  form.touched.partners_segment_portfolio
                    ? form.errors.partners_segment_portfolio
                    : undefined
                }
                value={form.values.partners_segment_portfolio}
                onChange={(event) => {
                  form.setFieldValue(
                    "partners_segment_portfolio",
                    event.target.value
                  );
                }}
              >
                {Object.values(EnumPartnerSegmentPortfolio).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label="Qual seu segmento de atuação?"
                id="partners_segment"
                error={
                  form.touched.partners_segment &&
                  !!form.errors.partners_segment
                }
                helperText={
                  form.touched.partners_segment
                    ? form.errors.partners_segment
                    : undefined
                }
                value={form.values.partners_segment}
                onChange={(event) => {
                  form.setFieldValue("partners_segment", event.target.value);
                }}
              >
                {Object.values(EnumPartnerSegment).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label={"Qual quantidade de vidas potenciais da sua carteira"}
                id="client_employees"
                error={
                  form.touched.client_employees &&
                  !!form.errors.client_employees
                }
                helperText={
                  form.touched.client_employees
                    ? form.errors.client_employees
                    : undefined
                }
                value={form.values.client_employees}
                onChange={(event) => {
                  form.setFieldValue("client_employees", event.target.value);
                }}
              >
                {Object.values(EnumPartnerAndClientEmployees).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField> 
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label="Qual seu principal interesse com a Caju?"
                id="partners_reason"
                error={
                  form.touched.partners_reason && !!form.errors.partners_reason
                }
                helperText={
                  form.touched.partners_reason
                    ? form.errors.partners_reason
                    : undefined
                }
                value={form.values.partners_reason}
                onChange={(event) => {
                  form.setFieldValue("partners_reason", event.target.value);
                }}
              >
                {Object.values(EnumPartnerReason).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <MultipleSelectField
                label="Quais produtos da Caju você tem interesse em vender?"
                id="products_to_sell__c"
                error={
                  form.touched.products_to_sell__c && !!form.errors.products_to_sell__c
                }
                helperText={
                  form.touched.products_to_sell__c
                  ? form.errors.products_to_sell__c
                  : undefined
                }
                value={form.values.products_to_sell__c}
                onChange={handleProductsToSellChange}
              >
                {Object.values(EnumProductsToSell).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </MultipleSelectField>
            </Grid>

            <Grid
              item
              container
              xs={12}
            >
              <Grid item xs={12} md={6}>
                <SelectField
                  label="Você trabalha com produtos para RH?"
                  id="RH_products__c"
                  error={
                    form.touched.RH_products__c && !!form.errors.RH_products__c
                  }
                  helperText={
                    form.touched.RH_products__c
                    ? form.errors.RH_products__c
                    : undefined
                  }
                  value={form.values.RH_products__c}
                  onChange={(event) => {
                    form.setFieldValue("RH_products__c", event.target.value);
                  }}
                >
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                </SelectField>
              </Grid>
              <Grid item xs={12} md={6}>
                {(form.values.RH_products__c === "Sim") && (<Box
                  sx={{ ml: { md: 4 }, mt: { xs: 2, md: 0 }, width: "100%" }}
                >
                <TextField
                  label={"Se sim, quais?"}
                  fullWidth
                  error={
                    form.touched.which_ones__c && !!form.errors.which_ones__c
                  }
                  helperText={
                    form.touched.which_ones__c
                      ? form.errors.which_ones__c
                      : undefined
                  }
                  {...form.getFieldProps("which_ones__c")}
                />
                </Box>)}
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                  label="Você tem urgência na implementação de algum cliente?
"
                  id="implementation_urgency__c"
                  error={
                    form.touched.implementation_urgency__c && !!form.errors.implementation_urgency__c
                  }
                  helperText={
                    form.touched.implementation_urgency__c
                    ? form.errors.implementation_urgency__c
                    : undefined
                  }
                  value={form.values.implementation_urgency__c}
                  onChange={(event) => {
                    form.setFieldValue("implementation_urgency__c", event.target.value);
                  }}
                >
                  <MenuItem value="Sim">Sim</MenuItem>
                  <MenuItem value="Não">Não</MenuItem>
                </SelectField>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{
              py: 1.5,
              px: 3,
              width: { xs: "100%", md: "auto" },
              fontSize: 16,
              mt: { xs: 2, md: 4 },
              mb: 3,
            }}
            disableElevation
            type="submit"
          >
            Enviar
          </Button>
        </form>
      </>
    </FormWrapper>
  );
};
