import { Typography, Grid, List } from "@mui/material";
import React from "react";
import { FormWrapper } from "../../../components/formWrapper";
import { EnumProducts } from "../../../services/caju/enums/enumProducts";
import { InterestOptionItem } from "./interestOptionItem";
import { Tokens } from "configs";
import { WarningCard } from "../step5/warningCard";
import { LoadingModal } from "./loadingModal";
import { EnumCajuInterest } from "services/caju/enums/enumCajuInterest";
import { getDtoFromStep4FormValues } from "./helper";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { Variables } from "configs/variables";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { LeadService } from "services/caju";

interface Step4Props {
  name?: string;
  products?: EnumProducts[];
  employeesNumber?: number | "";
  externalId: string;
  onGoNext: () => void;
}

export const Step4 = (props: Step4Props) => {
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const hasLessThan10Employees =
    props?.employeesNumber && props?.employeesNumber < 10;
  const dontAllowWellhubInCajuMais =
    hasLessThan10Employees && props.products?.includes(EnumProducts.CAJU_MAIS);

  const queueContext = React.useContext(QueuedUpdateContext);

  const handleAlreadyKnow = (value: EnumCajuInterest) => {
    TagManagerService.push(
      EnumGTMEvents.INTEREST_INFO_INTEREST_OPTION_SIGNUP_NOW__CLICK
    );
    const data = getDtoFromStep4FormValues(props.externalId, value, queueContext.id);
    queueContext.push(
      data, 
      LeadService.saveLead, 
      () => window.open(Variables.signupLink, "_self", "noopener noreferrer")
    );

    setIsOpenModal(true);
  };

  const handleScheduleMeeting = () => {
    TagManagerService.push(
      EnumGTMEvents.INTEREST_INFO_INTEREST_OPTION_SCHEDUALED_MEETING__CLICK
    );
    props.onGoNext();
  };

  const getFirstName = (name?: string) => name?.split(" ")[0];

  return (
    <FormWrapper>
      <>
        <LoadingModal open={isOpenModal} hasError={queueContext.error} />
        <Grid container>
          <Grid item xs={12} md={7} sx={{ mb: 3 }}>
            <Typography
              variant="subtitle1"
              sx={{ "&:first-letter": { textTransform: "capitalize" } }}
            >
              {getFirstName(props.name)}, agora é com você:
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <form data-cy="step4-form">
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <InterestOptionItem
                  title="Quero cadastrar minha empresa agora"
                  description="Finalize o cadastro e comece a desfrutar do Portal das Empresas Caju"
                  onClick={() => {
                    handleAlreadyKnow(EnumCajuInterest.ALREADY_KNOW);
                  }}
                />

                <InterestOptionItem
                  title="Quero participar do webinar gratuito"
                  description="Participe do nosso webinar e tire todas as suas dúvidas em um papo coletivo, antes de contratar as soluções da Caju"
                  onClick={handleScheduleMeeting}
                />
              </List>
            </form>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          {dontAllowWellhubInCajuMais && (
            <Grid item xs={12} md={4} mb={2}>
              <WarningCard
                title="Importante"
                isAccordion={false}
                open={true}
                setOpen={() => {}}
              >
                <>
                  <Typography
                    variant="body1"
                    color={Tokens.feedbackColor.warning.dark}
                  >
                    No momento a oferta de Wellhub{" "}
                    <strong>não está disponível</strong> para empresas com menos
                    de 9 colaboradores.
                  </Typography>
                  <Typography
                    variant="body1"
                    color={Tokens.feedbackColor.warning.dark}
                  >
                    Não se preocupe, você pode seguir conhecendo Caju Mais com
                    os serviços da Conexa Saúde, Optum e SAT Ivoor.
                  </Typography>
                </>
              </WarningCard>
            </Grid>
          )}
        </Grid>
      </>
    </FormWrapper>
  );
};
