import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { NextButton } from "../../../components/nextButton";
import { FormWrapper } from "components/formWrapper";
import { useStep2Form } from "./useStep2Form";
import { CNPJTextField } from "components/cnpjTextField";
import { SelectField } from "components/SelectField";
import { EnumPartnerPortfolioCNPJ } from "services/caju/enums/partnerForm/enumPartnerPortfolioCNPJ";
import React from "react";
import { PartnerFormContext } from "../partnerFormContext/context";
import { getDtoFromStep2PartnerFormValues } from "./useStep2Form/helper";
import { EnumPartnerAndClientEmployees } from "services/caju/enums/partnerForm/enumClientEmployees";
import { TagManagerService } from "services/tagManager";
import { EnumPartnerFormEvents } from "services/tagManager/enums/enumPartnerFormEvents";

interface Step2Props {
  goNext: () => void;
}

export const Step2 = (props: Step2Props) => {
  const partnerFormContext = React.useContext(PartnerFormContext);

  const form = useStep2Form({
    onSubmit: (values) => {
      const data = getDtoFromStep2PartnerFormValues(values);
      partnerFormContext.updateFormValues(data);
      handleSubmitEvent();
      props.goNext();
    },
  });

  const handleSubmitEvent = () => {
    TagManagerService.push(
      EnumPartnerFormEvents.PARTNERS_FORM__COMPANY_INFO__FORM_BTN__CONTINUE_CLICK
    );
  };

  return (
    <FormWrapper>
      <>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
        >
          <Typography variant="subtitle1">Sobre a sua empresa</Typography>
        </Grid>

        <form data-cy="step2-partner-form" onSubmit={form.handleSubmit}>
          <Grid container gap={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label={"Nome da empresa*"}
                fullWidth
                error={form.touched.company_name && !!form.errors.company_name}
                helperText={
                  form.touched.company_name
                    ? form.errors.company_name
                    : undefined
                }
                {...form.getFieldProps("company_name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CNPJTextField
                label={"CNPJ da empresa*"}
                fullWidth
                error={form.touched.company_CNPJ && !!form.errors.company_CNPJ}
                helperText={
                  form.touched.company_CNPJ
                    ? form.errors.company_CNPJ
                    : undefined
                }
                {...form.getFieldProps("company_CNPJ")}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label="Número total de colaboradores da sua empresa*"
                id="employees"
                error={form.touched.employees && !!form.errors.employees}
                helperText={
                  form.touched.employees ? form.errors.employees : undefined
                }
                value={form.values.employees}
                onChange={(event) => {
                  form.setFieldValue("employees", event.target.value);
                }}
              >
                {Object.values(EnumPartnerAndClientEmployees).map((value) => (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <SelectField
                label="Tamanho da Carteira do Clientes (CNPJ apenas)"
                id="partners_portfolio_CNPJ"
                error={
                  form.touched.partners_portfolio_CNPJ &&
                  !!form.errors.partners_portfolio_CNPJ
                }
                helperText={
                  form.touched.partners_portfolio_CNPJ
                    ? form.errors.partners_portfolio_CNPJ
                    : undefined
                }
                {...form.getFieldProps("partners_portfolio_CNPJ")}
                onChange={(event) => {
                  form.setFieldValue(
                    "partners_portfolio_CNPJ",
                    event.target.value
                  );
                }}
              >
                <MenuItem value={EnumPartnerPortfolioCNPJ["UM"]}>
                  {EnumPartnerPortfolioCNPJ["UM"]}
                </MenuItem>
                <MenuItem value={EnumPartnerPortfolioCNPJ["2-9"]}>
                  {EnumPartnerPortfolioCNPJ["2-9"]}
                </MenuItem>
                <MenuItem value={EnumPartnerPortfolioCNPJ["10-20"]}>
                  {EnumPartnerPortfolioCNPJ["10-20"]}
                </MenuItem>
                <MenuItem value={EnumPartnerPortfolioCNPJ["21-50"]}>
                  {EnumPartnerPortfolioCNPJ["21-50"]}
                </MenuItem>
                <MenuItem value={EnumPartnerPortfolioCNPJ["50-100"]}>
                  {EnumPartnerPortfolioCNPJ["50-100"]}
                </MenuItem>
                <MenuItem value={EnumPartnerPortfolioCNPJ["100+"]}>
                  {EnumPartnerPortfolioCNPJ["100+"]}
                </MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <NextButton type="submit" />
        </form>
      </>
    </FormWrapper>
  );
};
