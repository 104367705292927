import { Grid, Link, TextField, Typography } from "@mui/material";
import { PhoneTextField } from "../../../components/phoneTextField";
import { NextButton } from "../../../components/nextButton";
import { FormWrapper } from "components/formWrapper";
import { useStep1Form } from "./useStep1Form";
import { PartnerFormContext } from "../partnerFormContext/context";
import React from "react";
import { getDtoFromStep1PartnerFormValues } from "./useStep1Form/helper";
import { TagManagerService } from "services/tagManager";
import { EnumPartnerFormEvents } from "services/tagManager/enums/enumPartnerFormEvents";
import { handleBackToWebsiteBtn } from "utils";

interface Step1Props {
  goNext: () => void;
}

export const Step1 = (props: Step1Props) => {
  const partnerFormContext = React.useContext(PartnerFormContext);

  const form = useStep1Form({
    onSubmit: (values) => {
      const data = getDtoFromStep1PartnerFormValues(values);
      partnerFormContext.updateFormValues(data);
      handleSubmitEvent();
      props.goNext();
    },
  });

  const handleSubmitEvent = () => {
    TagManagerService.push(
      EnumPartnerFormEvents.PARTNERS_FORM__CONTACT_INFO__FORM_BTN__CONTINUE_CLICK
    );
  };

  return (
    <FormWrapper>
      <>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
        >
          <Typography variant="subtitle1">Revenda Caju</Typography>
          <Typography variant="body1">
            Se interessou em entrar para o time de revendedores da Caju?
            Preencha o formulário e entraremos em contato.
          </Typography>
          <Typography variant="subtitle1">Informações para contato</Typography>
        </Grid>

        <form data-cy="step1-partner-form" onSubmit={form.handleSubmit}>
          <Grid container gap={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label={"Nome do parceiro*"}
                fullWidth
                error={form.touched.name && !!form.errors.name}
                helperText={form.touched.name ? form.errors.name : undefined}
                {...form.getFieldProps("name")}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <TextField
                label={"E-mail profissional*"}
                fullWidth
                error={form.touched.email && !!form.errors.email}
                helperText={form.touched.email ? form.errors.email : undefined}
                {...form.getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneTextField
                label={"Celular*"}
                fullWidth
                error={form.touched.phone && !!form.errors.phone}
                helperText={form.touched.phone ? form.errors.phone : undefined}
                {...form.getFieldProps("phone")}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography sx={{ color: "medium.dark" }}>
                Ao continuar, esteja ciente da{" "}
                <Link
                  component="button"
                  sx={{
                    color: "medium.dark",
                    textDecorationColor: "#767676",
                    verticalAlign: "baseline",
                  }}
                  onClick={() =>
                    handleBackToWebsiteBtn(
                      "https://www.caju.com.br/privacidade",
                      EnumPartnerFormEvents.PARTNERS_FORM__CONTACT_INFO__FORM_LINK__POLITICA_DE_PRIVACIDADE__CLICK,
                      true
                    )
                  }
                >
                  política de privacidade
                </Link>{" "}
                da Caju.
              </Typography>
            </Grid>
          </Grid>
          <NextButton type="submit" />
        </form>
      </>
    </FormWrapper>
  );
};
