import { TextField, TextFieldProps } from '@mui/material'
import * as React from 'react'

export function maskedTextFieldFactory(maskFn: (str?: string) => string) {
  return (props: TextFieldProps) => {
    return <TextField
      {...props}
      value={maskFn(props.value as any)}
      onChange={(evt) => {
        if (props.onChange) {
          evt.target.value = maskFn(evt.target.value)
          props.onChange(evt)
        }
      }}
    />
  }
}