import { TypographyClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTypographyOverrides: Partial<
  OverridesStyleRules<TypographyClassKey, {}>
> = {
  root: {},
  h1: {
    fontSize: Tokens.fontSize.display,
    fontWeight: Tokens.fontWeight.bold,
  },
  h2: {
    fontSize: Tokens.fontSize.massive,
    fontWeight: Tokens.fontWeight.bold,
  },
  h3: {
    fontSize: Tokens.fontSize.giant,
    fontWeight: Tokens.fontWeight.bold,
  },
  h4: {
    fontSize: Tokens.fontSize.xxl,
    fontWeight: Tokens.fontWeight.bold,
  },
  h5: {
    fontSize: Tokens.fontSize.xl,
    fontWeight: Tokens.fontWeight.bold,
  },
  h6: {
    fontSize: Tokens.fontSize.lg,
    fontWeight: Tokens.fontWeight.bold,
  },
  subtitle1: {
    fontSize: Tokens.fontSize.md,
    fontWeight: Tokens.fontWeight.semibold,
  },
  subtitle2: {
    fontSize: Tokens.fontSize.sm,
    fontWeight: Tokens.fontWeight.semibold,
  },
  body1: {
    fontSize: Tokens.fontSize.sm,
  },
  body2: {
    fontSize: Tokens.fontSize.xs,
  },
  caption: {
    fontSize: Tokens.fontSize.xxs,
  },
};
