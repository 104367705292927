import { Typography, Grid, Alert } from "@mui/material";
import React from "react";
import { FormWrapper } from "../../../components/formWrapper";
import { NextButton } from "../../../components/nextButton";
import { useStep3Form } from "./useStep3Form";
import { ProductList } from "./productsList";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { getDtoFromStep3FormValues } from "./useStep3Form/helper";
import { IStep3FormValues } from "./useStep3Form/iStep3FormValues";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { formatterSelectedProducts } from "services/tagManager/utils";
import { WarningCard } from "../step5/warningCard";
import { Tokens } from "configs";
import { EnumProducts } from "services/caju/enums/enumProducts";

import { IStep1FormValues } from "../step1/useStep1Form/iStep1FormValues";
import { IStep2FormValues } from "../step2/useStep2Form/iStep2FormValues";
import { LeadService } from "services/caju";
import { getDtoFromStep1FormValues } from "../step1/useStep1Form/helpers";
import { getDtoFromStep2FormValues } from "../step2/useStep2Form/helper";

export interface Step3Props {
  isWellhubAvailable: boolean;
  onGoNext: (values: IStep3FormValues) => void;
  step1Values?: IStep1FormValues;
  step2Values?: IStep2FormValues;
}

export const Step3 = (props: Step3Props) => {
  const queueContext = React.useContext(QueuedUpdateContext);

  const form = useStep3Form({
    onSubmit: (values) => {
      const leadID = props?.step1Values?.email || ""
      let previousSteps = {};
      
      if (props.step1Values && props.step2Values) {
        previousSteps = {
          ...getDtoFromStep1FormValues(props.step1Values.email, {
            ...props.step1Values,
          }),
          ...getDtoFromStep2FormValues(
            props.step1Values.email,
            props.step2Values
          ),
        };
      }

      const data = {
        ...previousSteps,
        ...getDtoFromStep3FormValues(leadID, values),
      }

      queueContext.push(data, LeadService.saveLead);
      handleSubmitEvent();
      props.onGoNext(values);
    },
  });

  const employeesNumber = Number(props.step2Values?.employeesNumber);
  const isCajuMaisAvailable =
    employeesNumber > 999 ||
    form.values.products.includes(EnumProducts.MULTIBENEFITS);

  const handleSubmitEvent = () => {
    TagManagerService.push(
      EnumGTMEvents.PRODUCT_INFO_FORM_BTN_CONTINUE__CLICK,
      {
        ...formatterSelectedProducts(form.values.products),
      }
    );
  };
  const handleProducListChange = (products: "" | EnumProducts[]) => {
    if (!form.touched.products) {
      form.setFieldTouched("products");
    }

    if (!Array.isArray(products)) {
      return form.setFieldValue("products", products);
    }

    // When employees number is less than 1000, Caju Mais
    // requires Multi Benefits to be selected.
    // Remove Caju Mais when Multi Benefits is removed.
    const previousHasMultiBenefits = form.values.products.includes(EnumProducts.MULTIBENEFITS);
    const currentHasMultiBenefits = products.includes(EnumProducts.MULTIBENEFITS);
    const shouldRemoveCajuMais =
      employeesNumber < 1000 &&
      previousHasMultiBenefits &&
      !currentHasMultiBenefits;

    form.setFieldValue(
      "products",
      shouldRemoveCajuMais
        ? products.filter((product) => product !== EnumProducts.CAJU_MAIS)
        : products
    );
  };

  React.useEffect(() => {
    // When employees number is less than 1000, Caju Mais
    // requires Multi Benefits to be selected.
    // Remove Caju Mais if employees number changes to bellow 1000
    // and Multi Benefits isn't selected.
    const hasMultiBenefits = form.values.products.includes(EnumProducts.MULTIBENEFITS);

    if (employeesNumber < 1000 && !hasMultiBenefits) {
      const newProducts = form.values.products.filter(
        (product) => product !== EnumProducts.CAJU_MAIS
      );

      form.setFieldValue("products", newProducts);
    }

    // `form` causes an infinite loop when added as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesNumber]);

  return (
    <FormWrapper>
      <>
        <Grid item xs={12} md={6} sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            O que sua empresa está buscando?
          </Typography>
          <Typography variant="body1" color="dark.light">
            Você pode selecionar mais de uma das soluções abaixo:
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6}>
            <form data-cy="step3-form" onSubmit={form.handleSubmit}>
              <ProductList
                isWellhubAvailable={props.isWellhubAvailable}
                isCajuMaisAvailable={isCajuMaisAvailable}
                error={!!form.errors.products}
                helperText={form.errors.products}
                values={form.values.products}
                onChange={handleProducListChange}
              />
              <NextButton type="submit" />
            </form>
          </Grid>

          <Grid item xs={0} md={1}></Grid>

          {!isCajuMaisAvailable && (
            <>
              <Grid item xs={12} md={4}>
                <WarningCard
                  title="Importante"
                  isAccordion={false}
                  open
                  setOpen={() => {}}
                >
                  <Typography
                    variant="body1"
                    color={Tokens.feedbackColor.warning.dark}
                  >
                    No momento a oferta de Caju Mais para empresas abaixo de
                    1000 colaboradores só está disponível em conjunto com
                    Multibenefícios.
                  </Typography>
                </WarningCard>

                {form.touched.products && form.errors.products && (
                  <Alert severity="error" variant="outlined">
                    {form.errors.products}
                  </Alert>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </>
    </FormWrapper>
  );
};
