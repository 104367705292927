import { Box, Grid } from "@mui/material";
import React from "react";
import { useStep } from "../../hooks/useStep";
import { PrevButton } from "../../components/prevButton";
import ProgressBar from "../../components/progressBar";

import { Step1 } from "./step1";
import { IStep1FormValues } from "./step1/useStep1Form/iStep1FormValues";
import { Step2 } from "./step2";
import { IStep2FormValues } from "./step2/useStep2Form/iStep2FormValues";
import { Step3 } from "./step3";
import { IStep3FormValues } from "./step3/useStep3Form/iStep3FormValues";

import { Step4 } from "./step4";
import { Step5 } from "./step5";
import { Step6 } from "./step6";
import { Step7 } from "./step7";
import { getNextStep } from "./utils";
import { TagManagerService } from "services/tagManager";
import { getPageViewEventHash } from "services/tagManager/utils";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { useUtmCookies } from "hooks/useCookie";

const Form: React.FC = () => {
  const [step1Values, setStep1Values] = React.useState<IStep1FormValues>();
  const [step2Values, setStep2Values] = React.useState<IStep2FormValues>();
  const [step3Values, setStep3Values] = React.useState<IStep3FormValues>();
  const stepper = useStep();

  const params = new URLSearchParams(window.location.search);
  const utmCookies = useUtmCookies({ params });

  const hasPrev = stepper.current !== 0;
  const employeesNumber = step2Values?.employeesNumber || 0;
  const isWellhubAvailable = employeesNumber > 9;

  React.useEffect(() => {
    goNextStepEvent(0);
  }, []);

  const goPrev = () => {
    TagManagerService.push(EnumGTMEvents.FORM_BODY_BACKWARD_BTN_BACK__CLICK);
    stepper.prev();
  };

  const goNextStepEvent = (currentStep: number) => {
    TagManagerService.push(getPageViewEventHash(currentStep), {
      step: `${currentStep + 1}`,
    });
  };

  const goNext = () => {
    stepper.next({
      callback: goNextStepEvent,
    });
  };

  const externalId = step1Values?.email || "";

  return (
    <Box sx={{ mx: { xs: 1, md: 9 } }}>
      <Grid
        container
        sx={{
          flexDirection: { xs: "column-reverse", md: "row" },
        }}
        columns={{ xs: 1, md: 12 }}
        display={stepper.current >= 5 ? "none" : "flex"}
      >
        <Grid item md={2}>
          {hasPrev && <PrevButton onClick={goPrev} />}
        </Grid>
        <Grid item xs={12} md={5}>
          <ProgressBar currentStepIndex={stepper.current} progressBarLength={3} />
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: { xs: 3, md: 6 },
          display: stepper.current >= 5 ? "none" : "block",
        }}
      >
        <div data-cy="step1-container" hidden={stepper.current !== 0}>
          <Step1
            queryEmail={params.get("email") ?? ""}
            utmValues={utmCookies.utmValues}
            onGoNext={(value) => {
              setStep1Values(value);
              goNext();
            }}
          />
        </div>

        <div data-cy="step2-container" hidden={stepper.current !== 1}>
          <Step2
            onGoNext={(value) => {
              setStep2Values(value);
              goNext();
            }}
            name={step1Values?.name}
          />
        </div>

        <div data-cy="step3-container" hidden={stepper.current !== 2}>
          <Step3
            isWellhubAvailable={isWellhubAvailable}
            onGoNext={(value) => {
              setStep3Values(value);
              stepper.goToStep(
                getNextStep(value.products, step2Values?.employeesNumber!),
                {
                  callback: goNextStepEvent,
                }
              );
            }}
            step1Values={step1Values}
            step2Values={step2Values}
          />
        </div>

        <div data-cy="step4-container" hidden={stepper.current !== 3}>
          <Step4
            externalId={externalId}
            name={step1Values?.name}
            employeesNumber={step2Values?.employeesNumber}
            products={step3Values?.products}
            onGoNext={goNext}
          />
        </div>

        <div data-cy="step5-container" hidden={stepper.current !== 4}>
          <Step5
            externalId={externalId}
            name={step1Values?.name}
            email={step1Values?.email}
            employeesNumber={step2Values?.employeesNumber}
            products={step3Values?.products}
            onGoNext={goNext}
          />
        </div>
      </Box>

      <Box>
        <div data-cy="step6-container" hidden={stepper.current !== 5}>
          <Step6
            employeesNumber={step2Values?.employeesNumber}
            products={step3Values?.products}
          />
        </div>

        <div data-cy="step7-container" hidden={stepper.current !== 6}>
          <Step7 />
        </div>
      </Box>
    </Box>
  );
};

export default Form;
