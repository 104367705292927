import { Grid } from "@mui/material";
import { ReactElement } from "react";

interface FormWrapperProps {
  children: ReactElement;
}
export const FormWrapper = (props: FormWrapperProps) => {
  return (
    <Grid container  sx={{ px: {xs: 2, md: 0}, pb: 2 }}>
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={10}>
        {props.children}
      </Grid>
    </Grid>
  );
};
