import { ListItemIconClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiListItemIconOverrides: Partial<
  OverridesStyleRules<ListItemIconClassKey, {}>
> = {
  root: {
    minWidth: "auto",
    marginRight: 8,
  },
};
