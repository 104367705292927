import { useFormik } from "formik";
import { IStep2FormValues } from "./iStep2FormValues";
import { validations } from "./validations";
import useZipCodeComplement from "hooks/useZipCodeComplement";
import { getFormattedZipCode } from "lib/data/formatters/cep";
import { isValidCep } from "utils";
import { useState } from "react";

export interface UseStep2FormProps {
  onSubmit: (values: IStep2FormValues) => void;
}

export function useStep2Form(props: UseStep2FormProps) {
  const { getComplement, loading } = useZipCodeComplement()
  const [isUfDisabled, setIsUfDisabled] = useState<boolean>(false)
  
  const form = useFormik<IStep2FormValues>({
    initialValues: {
      company: "",
      employeesNumber: "",
      whereMeetCaju: "",
      cep: "",
      uf: "",
      website: "",
      linkedin: "",
      expectedLeadId: '',
    },
    validationSchema: validations,
    onSubmit: props.onSubmit,
  });

  async function setUfField(cep: string) {
    try {
      const complement = await getComplement(cep)
      
      await form.setValues({
        ...form.values,
        uf: complement?.uf || '',
        cep: cep,
      })
      
      setIsUfDisabled(true)
    } catch (error) {
      await form.setFieldTouched('cep', true)
      form.setFieldError('cep', 'CEP não encontrado. Por favor, verifique o número.')
    }
  }

  async function handleCepChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsUfDisabled(false)
    const cep = getFormattedZipCode(event.currentTarget.value)
    
    await form.setFieldValue('cep', cep)

    if (isValidCep(cep)) await setUfField(cep)
  }

  return {
    ...form,
    handleCepChange,
    isComplementLoading: loading,
    isUfDisabled,
  };
}
