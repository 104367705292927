import { AccordionSummaryClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiAccordionSummaryOverrides: Partial<
  OverridesStyleRules<AccordionSummaryClassKey, {}>
> = {
  root: {
    marginBottom: -1,
    minHeight: 56,
    "& p": {
      fontWeight: Tokens.fontWeight.semibold,
      color: Tokens.neutralColor.medium["400"],
    },

    "&$expanded": {
      minHeight: 56,
    },

    "& .MuiAccordionSummary-content": {
      margin: `0px`,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
};
