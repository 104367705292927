import { TooltipClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTooltipOverrides: Partial<
  OverridesStyleRules<TooltipClassKey, {}>
> = {
  tooltip: {
    fontSize: Tokens.fontSize.xs,
    backgroundColor: Tokens.neutralColor.dark["200"],
  },
  arrow: {
    color: Tokens.neutralColor.dark["200"],
  },
};
