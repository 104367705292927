import React, { useState } from "react";

export interface UseStepProps {
  initalStep?: number;
}

interface ICurrentStep {
  previous: number | undefined;
  current: number;
}

interface INavigationCallbackProps {
  callback?: (current: number) => void;
}

export const useStep = (props?: UseStepProps) => {
  const [current, setCurrent] = useState<ICurrentStep>({
    previous: undefined,
    current: props?.initalStep ?? 0,
  });

  const next = React.useCallback((props?: INavigationCallbackProps) => {
    setCurrent((prevState) => {
      const nextCurrent = prevState.current + 1;

      props?.callback?.(nextCurrent);

      return { previous: prevState.current, current: nextCurrent };
    });
  }, []);

  const prev = React.useCallback(() => {
    setCurrent((prevState) => ({
      previous:
        prevState.previous && prevState.previous > 0
          ? prevState.previous - 1
          : prevState.previous,
      current: prevState.previous ? prevState.previous : 0,
    }));
  }, []);

  const goToStep = React.useCallback(
    (step: number, callback?: INavigationCallbackProps) => {
      setCurrent((prevState) => {
        callback?.callback?.(step);

        return {
          previous: prevState.current,
          current: step,
        };
      });
    },
    []
  );

  return {
    current: current.current,
    previous: current.previous,
    next,
    prev,
    goToStep,
  };
};
