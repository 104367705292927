import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactComponent as AlimentacaoSvg } from "assets/images/icons/categorias-alimentacao.svg";
import { ReactComponent as AuxilioAlimentacaoSvg } from "assets/images/icons/categorias-auxilioalimentacao.svg";
import { ReactComponent as CulturaSvg } from "assets/images/icons/categorias-cultura.svg";
import { ReactComponent as EducacaoSvg } from "assets/images/icons/categorias-educacao.svg";
import { ReactComponent as HomeOfficeSvg } from "assets/images/icons/categorias-homeoffice.svg";
import { ReactComponent as MobilidadeSvg } from "assets/images/icons/categorias-mobilidade.svg";
import { ReactComponent as RefeicaoSvg } from "assets/images/icons/categorias-refeicao.svg";
import { ReactComponent as SaudeSvg } from "assets/images/icons/categorias-saude.svg";
import { ModalWrapper } from "components/modalWrapper";

export interface MultibenefitsModalProps {
  open: boolean;
  onClose: () => void;
}

export const MultibenefitsModal = (props: MultibenefitsModalProps) => {
  return (
    <ModalWrapper
      title="O que é Caju Multibenefícios?"
      subtitle="A melhor forma de oferecer vários benefícios aos colaboradores, através de um único cartão bandeira Visa."
      open={props.open}
      handleClose={props.onClose}
    >
      <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          <Typography variant="subtitle2" color="dark.dark" marginBottom={1}>
            Categorias fixas:
          </Typography>
          <Typography variant="body2">
            Exclusivo para alimentação do trabalhador, conforme a Lei
            14.442/2022
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <AuxilioAlimentacaoSvg />
              </ListItemIcon>
              <ListItemText
                primary="Auxílio-alimentação"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <RefeicaoSvg />
              </ListItemIcon>
              <ListItemText
                primary="Refeição"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AlimentacaoSvg />
              </ListItemIcon>
              <ListItemText
                primary="Alimentação"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2" color="dark.dark" marginBottom={1}>
            Categorias flexíveis:
          </Typography>
          <Typography variant="body2">
            O colaborador pode transferir o saldo entre as categorias.
          </Typography>
          <List sx={{ pt: 2 }}>
            <ListItem>
              <ListItemIcon>
                <HomeOfficeSvg />
              </ListItemIcon>
              <ListItemText
                primary="Auxílio home-office"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MobilidadeSvg />
              </ListItemIcon>
              <ListItemText
                primary="Mobilidade"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SaudeSvg />
              </ListItemIcon>
              <ListItemText
                primary="Saúde"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EducacaoSvg />
              </ListItemIcon>
              <ListItemText
                primary="Educação"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CulturaSvg />
              </ListItemIcon>
              <ListItemText
                primary="Cultura"
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};
