import { EnumProducts } from "services/caju/enums/enumProducts";

export const getNextStep = (
  products: EnumProducts[],
  employeesNumber: number | ""
) => {
  if (employeesNumber && employeesNumber >= 1000) return 6

  const isMultipleWithMultibenefitsWithoutCiclos =
    products.length > 1 && !products.includes(EnumProducts.CAJU_CICLOS) && products.includes(EnumProducts.MULTIBENEFITS);

  const isMultibenefitsOrAwards = products.every(
    (product) =>
      product === EnumProducts.MULTIBENEFITS || product === EnumProducts.AWARDS
  );

  const isOnlyMultibenefitsOrCajuMais = products.every(
    (product) =>
      product === EnumProducts.MULTIBENEFITS || product === EnumProducts.CAJU_MAIS || product === EnumProducts.AWARDS
  );

  const goesToStep7Employee = employeesNumber && (employeesNumber >= 20 && employeesNumber <= 99)

  const goesToStep4 = employeesNumber &&
    employeesNumber < 20 &&
    (isMultibenefitsOrAwards || isMultipleWithMultibenefitsWithoutCiclos) 

  const goesToStep7 = goesToStep7Employee && (isOnlyMultibenefitsOrCajuMais || products.length > 1) 

  if(goesToStep4) return 3

  if(goesToStep7) return 6

  return 4
};
