export function getFormattedPhone(str?: string) {
  if (!str)
    return ''

  const onlyNumbers = str.replace(/[^0-9]/g, '')

  if (onlyNumbers.length <= 2)
    return onlyNumbers
  
  if (onlyNumbers.length < 11)
    return getRegularMask(onlyNumbers)

  return getExtendedMask(onlyNumbers)
}

function getRegularMask(str: string) {
  const onlyNumbers = str.replace(/[^0-9]/g, '')
  const parts = /(\d{1,2})?(\d{1,4})?(\d{1,4})?/.exec(onlyNumbers)

  if (!parts || !parts[0])
    return ''

  return `(${parts[1]})` +
    (parts[2] ? ` ${parts[2]}` : '') +
    (parts[3] ? `-${parts[3]}` : '')
}

function getExtendedMask(str: string) {
  const onlyNumbers = str.replace(/[^0-9]/g, '')
  const parts = /(\d{1,2})?(\d{1,5})?(\d{1,4})?/.exec(onlyNumbers)

  if (!parts || !parts[0])
    return ''

  return `(${parts[1]})` +
    (parts[2] ? ` ${parts[2]}` : '') +
    (parts[3] ? `-${parts[3]}` : '')
}