export const Tokens = {
  neutralColor: {
    light: {
      "1000": "#ffffff",
      "900": "#fafafa",
      "800": "#f0f0f0",
    },
    medium: {
      "700": "#e0e0e0",
      "600": "#c6c6c6",
      "500": "#999999",
      "400": "#767676",
    },
    dark: {
      "300": "#4d4d4d",
      "200": "#252525",
      "100": "#000000",
    },
  },
  fontSize: {
    xxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "20px",
    lg: "24px",
    xl: "32px",
    xxl: "40px",
    giant: "48px",
    massive: "56px",
    display: "72px",
  },
  lineHight: {
    default: "100%",
    sm: "120%",
    md: "130%",
    lg: "140%",
    xl: "160%",
    xxl: "200%",
  },
  spacing: {
    pico: "4px",
    nano: "8px",
    micro: "12px",
    mili: "16px",
    centi: "20px",
    deci: "24px",
    deka: "32px",
    hecto: "40px",
    kilo: "48px",
    mega: "56px",
    giga: "64px",
    tera: "80px",
    peta: "96px",
  },
  borderRadius: {
    none: "0px",
    small: "4px",
    medium: "8px",
    large: "16px",
    pill: "500px",
  },
  borderWidth: {
    none: "0px",
    thin: "1px",
    thick: "2px",
    heavy: "4px",
  },
  shadow: {
    low: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
    medium: "0px 4px 16px 0px rgba(0, 0, 0, 0.2)",
    high: "0px 8px 24px 0px rgba(0, 0, 0, 0.2)",
  },
  padding: {
    pico: "4px",
    nano: "8px",
    micro: "12px",
    mili: "16px",
    centi: "20px",
    deci: "24px",
    deka: "32px",
    hecto: "40px",
    kilo: "48px",
    mega: "56px",
    giga: "64px",
  },
  interfaceColor: {
    primary: {
      pure: "#E80537",
      light: "#FFE6EC",
      medium: "#A20326",
      dark: "#5D0216",
    },
  },
  feedbackColor: {
    success: {
      light: "#7DEDCF",
      pure: "#10A87F",
      medium: "#08684E",
      dark: "#013325",
    },
    error: {
      light: "#FF919A",
      pure: "#F5616D",
      medium: "#BF4953",
      dark: "#33090d",
    },
    warning: {
      light: "#FFC16B",
      pure: "#EB8F1E",
      medium: "#BF6E09",
      dark: "#402309",
    },
  },
  fontFamily: {
    product: "Inter",
    marketing: "Faktum",
  },
  fontWeight: {
    regular: "regular",
    medium: "medium",
    semibold: 600,
    bold: 700,
  },
  categoryColor: {
    meal: {
      ultraLight: "#F8B4C3",
      light: "#F18184",
      pure: "#E80537",
      medium: "#74031C",
      dark: "#3A010E",
    },
    food: {
      ultraLight: "#FFD5B3",
      light: "#FFAC66",
      pure: "#FF7500",
      medium: "#803B00",
      dark: "#401D00",
    },
    foodAid: {
      ultraLight: "#FAB5A7",
      light: "#F89682",
      pure: "#F4502F",
      medium: "#7A2818",
      dark: "#3D140C",
    },
    transportation: {
      ultraLight: "#FFE9A7",
      light: "#FFE082",
      pure: "#FFCB2E",
      medium: "#806617",
      dark: "#40330C",
    },
    health: {
      ultraLight: "#B9EDB9",
      light: "#9BE59B",
      pure: "#59D359",
      medium: "#163516",
      dark: "#041300",
    },
    culture: {
      ultraLight: "#BAE7F4",
      light: "#74CFE8",
      pure: "#17AFD9",
      medium: "#0C586D",
      dark: "#062C36",
    },
    homeOffice: {
      ultraLight: "#D6C8EE",
      light: "#C1ABE6",
      pure: "#6C3DC2",
      medium: "#361F61",
      dark: "#1B0F31",
    },
    education: {
      ultraLight: "#F3AFCF",
      light: "#F59BC5",
      pure: "#EE589E",
      medium: "#772C4F",
      dark: "#3C1628",
    },
    cashew: {
      ultraLight: "#EDC1E7",
      light: "#DA83CF",
      pure: "#AC1B99",
      medium: "#560E4D",
      dark: "#300129",
    },
  },
} as const;
