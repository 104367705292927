import * as React from "react";
import { QueuedUpdateContext } from "./context";
import { AxiosError } from "axios";
import { QueueItem } from "./types";
export interface QueuedUpdateProviderProps {
  children?: any;
}

export function QueuedUpdateProvider(props: QueuedUpdateProviderProps) {
  const [loading, setLoading] = React.useState(false);
  const [id, setId] = React.useState('')
  const [error, setError] = React.useState<AxiosError>();
  const queueRef = React.useRef<QueueItem[]>([]);
  const isLoadingRef = React.useRef(false);

  const update = React.useCallback(async () => {
    if (isLoadingRef.current) return;
    try {
      setLoading(true);

      const item = queueRef.current.shift();

      if (item) {
        isLoadingRef.current = true;
        
        const response = await item.clientFunction(item.data);
        
        if (response.id && !id) setId(response.id)

        isLoadingRef.current = false;
        if (item.callback) {
          item.callback();
        }

        update();
      }
    } catch (err: any) {
      setError(err);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const push = React.useCallback(
    (data: QueueItem["data"], clientFunction: QueueItem["clientFunction"], callback: QueueItem["callback"]) => {
      queueRef.current.push({ data, clientFunction, callback });
      update();
    },
    [update]
  );

  return (
    <QueuedUpdateContext.Provider value={{ loading, error, push, id }}>
      {props.children}
    </QueuedUpdateContext.Provider>
  );
}
