import { OutlinedInputClassKey, outlinedInputClasses } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiOutlinedInputOverrides: Partial<
  OverridesStyleRules<OutlinedInputClassKey, {}>
> = {
  notchedOutline: {
    borderColor: "#999999",
  },
  root: {
    borderRadius: "8px",
    color: "#767676",
    "& input": {
      padding: "16px 12px",
    },
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#252525",
    },
    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#252525",
      borderWidth: "2px",
    },
    [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "#252525",
      borderWidth: "2px",
    },
  },
};
