import { OverridesStyleRules } from "@mui/material/styles/overrides";
import { TouchRippleClassKey } from "@mui/material";

export const MuiRippleOverrides: Partial<
  OverridesStyleRules<TouchRippleClassKey, {}>
> = {
  root: {
    opacity: 0,
  },
};
