import React from "react";
import { Grid, Typography } from "@mui/material";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { FormWrapper } from "../../../components/formWrapper";
import { Tokens } from "../../../configs/tokens/tokens";
import { InfoCard } from "./infoCard";
import { WarningCard } from "./warningCard";
import { getCalendlyLink } from "./utils";
import { EnumProducts } from "services/caju/enums/enumProducts";
import { getDtoFromStep4FormValues } from "../step4/helper";
import { EnumCajuInterest } from "services/caju/enums/enumCajuInterest";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { LeadService } from "services/caju";

interface Step5Props {
  name?: string;
  email?: string;
  employeesNumber?: number | "";
  products?: EnumProducts[];
  externalId: string;
  onGoNext: () => void;
}

export function Step5(props: Step5Props) {
  const [openInfoCard, setOpenInfoCard] = React.useState(false);
  const [openWarningCard, setOpenWarningCard] = React.useState(false);
  const [dateAndTimeSelected, setdateAndTimeSelected] = React.useState(false);

  const queueContext = React.useContext(QueuedUpdateContext);

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      setdateAndTimeSelected(true);
      TagManagerService.push(
        EnumGTMEvents.MEETING_INFO_DATE_OPTION_DATE__CLICK
      );
    },
    onEventTypeViewed: () => {
      setdateAndTimeSelected(false);
    },
    onEventScheduled: () => {
      handleBookedMetting(EnumCajuInterest.MAKE_APOINTMENT);
      TagManagerService.push(
        EnumGTMEvents.MEETING_INFO_SCHEDULING_CONFIRMATION_SUCCESS__DATA_SENT
      );
      if (queueContext.error) {
        TagManagerService.push(EnumGTMEvents.ERROR_MSG__VIEW);
      }
    },
  });

  const itsOnlyAwards = props?.products?.every(
    (product) => product === EnumProducts.AWARDS
  );

  const styles = {
    height: "750px",
  };

  const handleBookedMetting = (value: EnumCajuInterest) => {
    const data = getDtoFromStep4FormValues(props.externalId, value, queueContext.id);
    queueContext.push(
      data,
      LeadService.saveLead, 
    );
    props.onGoNext();
  };

  const pageSettings = {
    backgroundColor: Tokens.neutralColor.light["1000"],
    primaryColor: Tokens.interfaceColor.primary.pure,
    textColor: Tokens.neutralColor.dark["200"],
    minHeight: "auto",
    overflowY: "hidden",
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
  };

  const hasLessThan10Employees =
    props?.employeesNumber && props?.employeesNumber < 10;

  const dontAllowWellhubInCajuMais =
    hasLessThan10Employees && props.products?.includes(EnumProducts.CAJU_MAIS);

  const calendlyLink =
    getCalendlyLink(props?.products, props?.employeesNumber) as unknown || "";
  
  return (
    <FormWrapper>
      <Grid
        item
        container
        xs={12}
        direction={{ xs: "column-reverse", lg: "row" }}
      >
        <Grid
          item
          xs={12}
          lg={dateAndTimeSelected ? 12 : 7}
          sx={{ mt: { xs: 2, lg: 0 } }}
        >
          {calendlyLink && (
            <InlineWidget
              url={calendlyLink as string}
              prefill={{ name: props?.name, email: props?.email }}
              pageSettings={pageSettings}
              styles={styles}
            />
          )}
        </Grid>
        <Grid item xs={0} lg={1}></Grid>
        <Grid
          item
          xs={12}
          lg={4}
          display={dateAndTimeSelected ? "none" : "block"}
        >
          {dontAllowWellhubInCajuMais && (
            <WarningCard
              title="Importante"
              isAccordion={true}
              open={openWarningCard}
              setOpen={setOpenWarningCard}
            >
              <>
                <Typography
                  variant="body1"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  No momento a oferta de Wellhub{" "}
                  <strong>não está disponível</strong> para empresas com menos
                  de 9 colaboradores.
                </Typography>
                <Typography
                  variant="body1"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  Não se preocupe, você pode seguir conhecendo Caju Mais com os
                  serviços da Conexa Saúde, Optum e SAT Ivoor.
                </Typography>
              </>
            </WarningCard>
          )}
          {props.employeesNumber && props.employeesNumber < 20 ? (
            <InfoCard
              title="Mesa Aberta com a Caju"
              subheader="Sobre a reunião"
              open={openInfoCard}
              setOpen={setOpenInfoCard}
            >
              <>
                <Typography variant="body2" color="dark.light">
                  O evento funciona como uma roda de conversa, onde um de nossos
                  especialistas fica a disposição para te apresentar a
                  plataforma e contar um pouquinho mais sobre a Caju.
                </Typography>
                <Typography variant="body2" color="dark.light">
                  Vamos falar sobre todos os nossos produtos e apresentar a
                  plataforma do RH.
                </Typography>
                <Typography variant="body2" color="dark.light">
                  Aproveite para tirar todas as suas dúvidas antes de começar a
                  usar Caju!
                </Typography>
              </>
            </InfoCard>
          ) : (
            <InfoCard
              title="Introdução Caju"
              subheader="Sobre a reunião"
              open={openInfoCard}
              setOpen={setOpenInfoCard}
            >
              <>
                <Typography variant="body2" color="dark.light">
                  Esse será um papo com uma pessoa do nosso time de vendas para
                  entender suas necessidades e direcionar sua experiência da
                  melhor forma
                </Typography>
                <Typography variant="body2" color="dark.light">
                  Aproveite para tirar todas as suas dúvidas antes de começar a
                  usar Caju!
                </Typography>
              </>
            </InfoCard>
          )}

          {itsOnlyAwards && (
            <WarningCard
              title="Importante"
              isAccordion={true}
              subheader="Sobre custos para premiar"
              open={openWarningCard}
              setOpen={setOpenWarningCard}
            >
              <>
                <Typography
                  variant="body2"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  Oferecemos cartões virtuais gratuitamente, uma opção
                  conveniente e ágil.
                </Typography>
                <Typography
                  variant="body2"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  Você pode adquirir cartões físicos para seus colaboradores
                  após realizar seu cadastro na plataforma.
                </Typography>
                <Typography
                  variant="body2"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  Se sua empresa contratar multi benefícios além de premiações,
                  a emissão e envio de cartões são gratuitos.
                </Typography>
                <Typography
                  variant="body2"
                  color={Tokens.feedbackColor.warning.dark}
                >
                  Para isso, volte à página anterior e selecione{" "}
                  <strong>também</strong> a opção "Caju Multibenefícios".
                </Typography>
              </>
            </WarningCard>
          )}
        </Grid>
      </Grid>
    </FormWrapper>
  );
}
