import { IGoogleAnalyticsProperties } from "./interfaces/iGoogleAnalyticsProperties";

export function googleAnalyticsAdapter<T>(data: T) {
  const properties: IGoogleAnalyticsProperties = {
    measurementId: "G-3RPJSREWE0",
    clientId: getGoogleAnalyticsClientId(),
  };

  return {
    ...data,
    ...properties,
  };
}

function getGoogleAnalyticsClientId() {
  try {
    const cookie: Record<string, string> = {};

    document.cookie.split(";").forEach((el) => {
      const splitCookie = el.split("=");
      const key = splitCookie[0].trim();
      const value = splitCookie[1];

      cookie[key] = value;
    });

    return cookie["_ga"].substring(6);
  } catch (error) {
    console.error("getGoogleAnalyticsClientId", error);

    return "";
  }
}
