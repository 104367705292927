import * as yup from "yup";
import { isValidPhone } from "lib/data/validators/phone";
import { EnumContactJob } from "services/caju/enums/enumContactJob";
import { AreaFields } from "services/caju/enums/enumArea";

export const validations = yup.object({
  name: yup.string().required("Nome é obrigatório"),

  email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),

  phone: yup
    .string()
    .test("phone format", "Informe o telefone válido", (value) =>
      isValidPhone(value || "")
    )
    .required("Telefone é obrigatório"),

  position: yup.string().required("Cargo é obrigatório"),
  
  other_position: yup.string().when('position', (position, schema) => 
    (position.includes(EnumContactJob.OTHER)) ? schema.required("Por favor, especifique o cargo") : schema
  ),
  
  area: yup.string().required("Área é obrigatório"),

  subarea: yup.string().when('area', (area, schema) => 
    (area.includes(AreaFields.HR.title) || area.includes(AreaFields.PURCHASING_AND_FINANCIAL.title)) ? 
      schema.required("Por favor, especifique a subárea") 
      : schema
  ),

  other_area: yup.string().when('area', (area, schema) => 
    (area.includes(AreaFields.OTHER.title)) ? schema.required("Por favor, especifique a área") : schema
  ),
});
