import { EnumProducts } from "services/caju/enums/enumProducts";
import { EnumTier } from "./enums/enumTier";
import { EnumGTMEvents } from "./enums/enumGTMEvents";
import { EnumPartnerFormEvents } from "./enums/enumPartnerFormEvents";

export const formatterSelectedProducts = (products: EnumProducts[]) => {
  const selected_option = {
    is_selected_option_1: products.includes(EnumProducts.MULTIBENEFITS),
    is_selected_option_2: products.includes(EnumProducts.CORP_EXPENSES),
    is_selected_option_3: products.includes(EnumProducts.AWARDS),
    is_selected_option_4: products.includes(EnumProducts.CAJU_MAIS),
    is_selected_option_5: products.includes(EnumProducts.CAJU_CICLOS),
  };

  return selected_option;
};

export const formatterEmployeeCountTier = (employeeNumber: number | "") => {
  if (!employeeNumber) return;
  if (employeeNumber > 1999) return EnumTier.MORE_THAN_2000;
  else if (employeeNumber > 500) return EnumTier.BETWEEN_500_TO_1999;
  else if (employeeNumber > 100) return EnumTier.BETWEEN_100_TO_499;
  else if (employeeNumber > 50) return EnumTier.BETWEEN_50_TO_99;
  else if (employeeNumber > 10) return EnumTier.BETWEEN_10_TO_49;
  else if (employeeNumber > 5) return EnumTier.BETWEEN_5_TO_9;
  else return EnumTier.BETWEEN_1_TO_4;
};

export const getPageViewEventHash = (step: number) => {
  const pageViewEventHashes = [
    EnumGTMEvents.CONTACT_INFO__VIEW,
    EnumGTMEvents.COMPANY_INFO__VIEW,
    EnumGTMEvents.PRODUCT_INFO__VIEW,
    EnumGTMEvents.INTEREST_INFO__VIEW,
    EnumGTMEvents.MEETING_INFO__VIEW,
    EnumGTMEvents.CONFIRMATION_INFO__VIEW,
    EnumGTMEvents.CICLOS_INFO__VIEW,
  ];

  return pageViewEventHashes[step];
};

export const getPartnerFormPageViewHash = (step: number) => {
  const partnerFormEventHashes = [
    EnumPartnerFormEvents.PARTNERS_FORM__CONTACT_INFO__VIEW,
    EnumPartnerFormEvents.PARTNERS_FORM__COMPANY_INFO__VIEW,
    EnumPartnerFormEvents.PARTNERS_FORM__PORTFOLIO_INFO__VIEW,
    EnumPartnerFormEvents.PARTNERS_FORM__CONFIRMATION_INFO__VIEW,
  ];

  return partnerFormEventHashes[step];
};
