import { AccordionDetailsClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiAccordionDetailsOverrides: Partial<
  OverridesStyleRules<AccordionDetailsClassKey, {}>
> = {
  root: {
    "& p": {
      color: Tokens.neutralColor.medium["400"],
    },
  },
};
