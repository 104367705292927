import { IUpdateLeadDataDto } from "../../../../services/caju/interfaces/iUpdateLeadDataDto";
import { IStep3FormValues } from "../useStep3Form/iStep3FormValues";

export function getDtoFromStep3FormValues(
  leadId: string,
  values: IStep3FormValues
) {
  const result: IUpdateLeadDataDto = {
    externalId: leadId,
    step: "3",
    products: values.products.join(";"),
    isLead: true,
  };

  return result;
}
