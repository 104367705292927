import { useFormik } from "formik";
import { IStep2FormValues } from "./iStep2FormValues";
import { validations } from "./validations";

export interface UseStep2FormProps {
  onSubmit: (values: IStep2FormValues) => void;
}

export function useStep2Form(props: UseStep2FormProps) {
  const form = useFormik<IStep2FormValues>({
    initialValues: {
      company_name: "",
      company_CNPJ: "",
      employees: "",
      partners_portfolio_CNPJ: "",
    },
    validationSchema: validations,
    onSubmit: props.onSubmit,
  });

  return {
    ...form,
  };
}
