export const getFormattedCnpj = (value?: string): string =>
  value
    ? value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
    : "";

export const removeCNPJPunctuation = (value?: string): string =>
  value ? value.replace(/\/|\.|-/gm, "") : "";
