import { AppBarClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiAppBarOverrides: Partial<
  OverridesStyleRules<AppBarClassKey, {}>
> = {
  root: {
    color: Tokens.neutralColor.light['1000'],
    boxShadow: "none"
  },
};
