import { useFormik } from "formik";
import { IStep1FormValues } from "./iStep1FormValues";
import { validations } from "./validations";

export interface UseStep1FormProps {
  onSubmit: (values: IStep1FormValues) => void;
  initialEmailValue: string;
}

export function useStep1Form(props: UseStep1FormProps) {
  const form = useFormik<IStep1FormValues>({
    initialValues: {
      name: "",
      email: props.initialEmailValue,
      phone: "",
      position: "",
      area: "",
      subarea: "",
      other_position:"",
      other_area: "",
    },
    validationSchema: validations,
    onSubmit: props.onSubmit,
  });

  return {
    ...form,
  };
}
