import { FormControlClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiFormControlOverrides: Partial<
  OverridesStyleRules<FormControlClassKey, {}>
> = {
  root: {
    "& label.Mui-focused": {
      color: "#252525",
      fontWeight: 500,
    },
    "& label.Mui-error": {
      color: "#252525",
    },
    "& input": {
      color: "#252525",
    },
    "& .MuiSelect-select": {
      color: "#252525",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: Tokens.feedbackColor.error.medium,
    },
  },
};
