import { AccordionClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiAccordionOverrides: Partial<
  OverridesStyleRules<AccordionClassKey, {}>
> = {
  root: {
    boxShadow: "none",
    borderBottom: `1px solid ${Tokens.neutralColor.medium["700"]}`,
    borderRadius: `0px !important`,

    "&:hover:not(&$disabled)": {
      backgroundColor: Tokens.neutralColor.light["900"],
    },

    "&$expanded": {
      margin: "auto",
    },

    "&$disabled": {
      backgroundColor: "trasparent",
    },

    "&:before": {
      display: "none",
    },
  },
};
