import { MenuItemClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiMenuItemOverrides: Partial<
  OverridesStyleRules<MenuItemClassKey, {}>
> = {
  root: {
    padding: "12px 24px",
    fontSize: "14px",
    color: "#252525",
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      fontWeight: 600,
      backgroundColor: "transparent",
      color: "#E80537",
    },
  },
};
