import { IPartnerLeadDataDto } from "services/caju/interfaces/iPartnerLeadDataDto";
import { IStep3FormValues } from "./iStep3FormValues";

export function getDtoFromStep3PartnerFormValues(values: IStep3FormValues) {
  const result: IPartnerLeadDataDto = {
    partnersSegment: values.partners_segment,
    partnersSegmentPortfolio: values.partners_segment_portfolio,
    clientEmployees: values.client_employees,
    partnersReason: values.partners_reason,

    productsToSell: values.products_to_sell__c.join(";"),
    rhProducts: values.RH_products__c,
    whichOnes: values.which_ones__c,
    implementationUrgency: values.implementation_urgency__c,

    utmSource: values.utm_data?.utm_source,
    utmMedium: values.utm_data?.utm_medium,
    utmCampaign: values.utm_data?.utm_campaign,
    utmTerm: values.utm_data?.utm_term,
    utmContent: values.utm_data?.utm_content,
  };

  return result;
}
