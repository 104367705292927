import { useFormik } from "formik";
import { IStep3FormValues } from "./iStep3FormValues";
import { validations } from "./validations";

export interface UseStep3FormProps {
  onSubmit: (values: IStep3FormValues) => void;
}

export function useStep3Form(props: UseStep3FormProps) {
  const form = useFormik<IStep3FormValues>({
    initialValues: {
      partners_segment: "",
      partners_segment_portfolio: "",
      client_employees: "",
      partners_reason: "",
      products_to_sell__c: [],
      RH_products__c: "",
      which_ones__c: "",
      implementation_urgency__c: "",
    },
    validationSchema: validations,
    onSubmit: props.onSubmit,
  });

  return {
    ...form,
  };
}
