import { EnumProducts } from "services/caju/enums/enumProducts";
import {
  EnumMultipleWithCiclos,
  EnumMultipleWithMultiWithoutCiclos,
  EnumMultipleWithoutMultiWithoutCiclos,
  EnumOnlyCajuMais,
  EnumOnlyCiclos,
  EnumOnlyDespesas,
  EnumOnlyMulti,
  EnumOnlyRewards,
} from "./enums";

export const getCalendlyLink = (products?: EnumProducts[], employeesNumber?: number | "") => {
  if (!products?.length || !employeesNumber) return;

  if (products.length === 1) return getLinkFromOneProduct(products[0], employeesNumber);

  return getLinkFromManyProducts(products, employeesNumber);
};

function getLinkFromOneProduct(product: EnumProducts, employeesNumber: number) {
  if (product === EnumProducts.MULTIBENEFITS) {
    if (employeesNumber >= 100) return EnumOnlyMulti["100+"];
    if (employeesNumber >= 20) return EnumOnlyMulti["20-99"];
    return EnumOnlyMulti["1-19"];
  }

  if (product === EnumProducts.AWARDS) {
    if (employeesNumber >= 100) return EnumOnlyRewards["100+"];
    if (employeesNumber >= 20) return EnumOnlyRewards["20-99"];
    return EnumOnlyRewards["1-19"];
  }

  if (product === EnumProducts.CAJU_MAIS) {
    if (employeesNumber >= 100) return EnumOnlyCajuMais["100+"];
    if (employeesNumber >= 20) return EnumOnlyCajuMais["20-99"];
    return EnumOnlyCajuMais["1-19"];
  }

  if (product === EnumProducts.CORP_EXPENSES) {
    if (employeesNumber >= 100) return EnumOnlyDespesas["100+"];
    if (employeesNumber >= 10) return EnumOnlyDespesas["10-99"];
    return EnumOnlyDespesas["1-9"];
  }

  if (product === EnumProducts.CAJU_CICLOS) {
    return EnumOnlyCiclos["1+"];
  }
}

function getLinkFromManyProducts(products: EnumProducts[], employeesNumber: number) {
  const hasCiclos = products.some((product) => product === EnumProducts.CAJU_CICLOS);
  const hasMulti = products.some((product) => product === EnumProducts.MULTIBENEFITS);

  if (hasCiclos) {
    if (employeesNumber >= 100) return EnumMultipleWithCiclos["100+"];
    if (employeesNumber >= 20) return EnumMultipleWithCiclos["20-99"];
    return EnumMultipleWithCiclos["1-19"];
  }

  if (!hasCiclos && hasMulti) {
    if (employeesNumber >= 100) return EnumMultipleWithMultiWithoutCiclos["100+"];
    if (employeesNumber >= 20) return EnumMultipleWithMultiWithoutCiclos["20-99"];
    return EnumMultipleWithMultiWithoutCiclos["1-19"];
  }

  if (!hasCiclos && !hasMulti) {
    if (employeesNumber >= 100) return EnumMultipleWithoutMultiWithoutCiclos["100+"];
    if (employeesNumber >= 20) return EnumMultipleWithoutMultiWithoutCiclos["20-99"];
    return EnumMultipleWithoutMultiWithoutCiclos["1-19"];
  }
}
