import { EnumGTMEvents } from "./enums/enumGTMEvents";
import { EnumPartnerFormEvents } from "./enums/enumPartnerFormEvents";
import { IEventDataParams } from "./interfaces/iEventDataParams";

export class TagManagerService {
  static previousParams: Partial<IEventDataParams> | undefined = {};

  static dataLayer = window.dataLayer || [];

  static setPreviousParams(parameters: IEventDataParams) {
    this.previousParams = parameters;
  }

  static push(
    event: EnumGTMEvents | EnumPartnerFormEvents,
    newParams: IEventDataParams = {}
  ) {
    const parameters = {
      ...this.previousParams,
      ...newParams,
    };

    this.setPreviousParams(parameters);

    this.dataLayer.push({
      event,
      parameters,
    });
  }

  static pushLabelTag(event: EnumGTMEvents, newParams: IEventDataParams) {
    const parameters = {
      ...this.previousParams,
      ...newParams,
    };

    this.dataLayer.push({
      event,
      parameters,
    });
  }
}
