import { EnumProducts } from "services/caju/enums/enumProducts";
import { Variables } from "configs/variables";

export const getPresentationLink = (
  products: EnumProducts[],
  employeesNumber: number
) => {
  const isOnlyExpenses = products.every(
    (product) => product === EnumProducts.CORP_EXPENSES
  );

  const isOnlyCajuCiclos = products.every(
    (product) => product === EnumProducts.CAJU_CICLOS
  );

  const isOnlyCajuMais =
    products.every((product) => product === EnumProducts.CAJU_MAIS);

  const moreThan50Employees = employeesNumber >= 50;
  const between49And10Employees = employeesNumber < 50 && employeesNumber >= 10;
  const less10Employees = employeesNumber < 10;

  if (isOnlyCajuCiclos) return Variables.presentationLinks.onlyCajuCiclos;

  if (isOnlyExpenses) return Variables.presentationLinks.onlyExpenses;

  if (isOnlyCajuMais && moreThan50Employees) return Variables.presentationLinks.onlyCajuMaisMoreThan50;

  if (isOnlyCajuMais && between49And10Employees) return Variables.presentationLinks.onlyCajuMaisBetween49and10;

  if (isOnlyCajuMais && less10Employees) return Variables.presentationLinks.onlyCajuMaisLessThan9;

  if (moreThan50Employees) return Variables.presentationLinks.anyMoreThan50;

  return Variables.presentationLinks.anyLessThan49;
};

export const downloadPresentation = (
  products: EnumProducts[],
  employeesNumber: number
) => {
  const presentationLink = getPresentationLink(products, employeesNumber);
  const downloadLink = document.createElement("a");
  const fileName = "Apresentação_Caju";

  downloadLink.href = presentationLink;
  downloadLink.setAttribute("download", fileName);
  downloadLink.target = "_blank";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};
