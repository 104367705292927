import * as yup from "yup";
import isValidCNPJ from "lib/data/validators/cnpj";

export const validations = yup.object({
  company_name: yup.string().required("Nome da empresa é obrigatório"),

  company_CNPJ: yup
    .string()
    .test("cnpj format", "Informe um CNPJ válido", (value) =>
      isValidCNPJ(value)
    )
    .required("CNPJ é obrigatório"),

  employees: yup
    .string()
    .required("O número total de colaboradores é obrigatório"),

  partners_portfolio_CNPJ: yup.string().required("Este campo é obrigatório"),
});
