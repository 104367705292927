import { Alert, Box, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { PhoneTextField } from "../../../components/phoneTextField";
import { FormWrapper } from "../../../components/formWrapper";
import { NextButton } from "../../../components/nextButton";
import { PositionSelect } from "./positionsSelect";
import { useStep1Form } from "./useStep1Form";
import { IStep1FormValues, IUtmData } from "./useStep1Form/iStep1FormValues";
import { commonEmails } from "./utils";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { TagManagerService } from "services/tagManager";
import { AreaSelect } from "./areaSelect";
import { EnumContactJob } from "services/caju/enums/enumContactJob";
import { AreaFields } from "services/caju/enums/enumArea";
import { SubareaSelect } from "./subareaSelect";
import { getDtoFromStep1FormValues } from "./useStep1Form/helpers";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { LeadService } from "services/caju";

export interface Step1Props {
  onGoNext: (values: IStep1FormValues) => void;
  utmValues: IUtmData | undefined;
  queryEmail: string;
}

export const Step1 = (props: Step1Props) => {
  const [subareaField, setSubareaField] = useState<string>("");
  const [isPositionDisabled, setIsPositionDisabled] = useState<boolean>(true);
  const queueContext = React.useContext(QueuedUpdateContext);

  const form = useStep1Form({
    onSubmit: (values) => {
      handleSubmitEvent(values);
      props.onGoNext({ ...values, utm_data: props.utmValues });
    },
    initialEmailValue: props.queryEmail ?? "",
  });

  const handleSubmitEvent = (values: IStep1FormValues) => {
    let data = {
      ...getDtoFromStep1FormValues(values.email || "", values),
    };

    if(queueContext.id) {
      data = {...data, expectedLeadId: queueContext.id}
    }

    queueContext.push(data, LeadService.putExpectedLead);

    TagManagerService.push(
      EnumGTMEvents.CONTACT_INFO_FORM_BTN_CONTINUE__CLICK,
      {
        email_domain: isCommonEmail ? "personal" : "corp",
        role: form.values.position,
      }
    );
  };

  const isCommonEmail = commonEmails.some((common) =>
    form.values.email.includes(common)
  );

  const handleAreaOnChange = (value: string) => {
    form.setFieldValue("area", value);

    setIsPositionDisabled(!value);

    form.setFieldTouched("other_area", false);
    form.setFieldTouched("subarea", false);
  };

  const handlePositionOnChange = (value: string) => {
    form.setFieldValue("position", value);

    form.setFieldTouched("other_position", false);
  };

  return (
    <FormWrapper>
      <>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}
        >
          <Typography variant="subtitle1">Boas vindas à Caju!</Typography>
          <Typography variant="body1">
            Preencha esse formulário de interesse e nosso time te apresentará as
            soluções mais saborosas do Brasil.
          </Typography>
          <Typography variant="subtitle1">Bora começar?</Typography>
        </Grid>

        <form data-cy="step1-form" onSubmit={form.handleSubmit}>
          <Grid container gap={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label={"Seu nome completo para contato*"}
                fullWidth
                error={form.touched.name && !!form.errors.name}
                helperText={form.touched.name ? form.errors.name : undefined}
                {...form.getFieldProps("name")}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Seu e-mail profissional*"}
                  fullWidth
                  error={form.touched.email && !!form.errors.email}
                  helperText={
                    form.touched.email ? form.errors.email : undefined
                  }
                  {...form.getFieldProps("email")}
                />
              </Grid>
              {isCommonEmail && (
                <Grid
                  item
                  xs={12}
                  md={5}
                  position={{ xs: "initial", md: "absolute" }}
                  right={{ md: -24 }}
                >
                  <Alert severity="info" variant="outlined">
                    Sugerimos utilizar o e-mail corporativo. Isso facilita o
                    preenchimento de campos na etapa de cadastro.
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneTextField
                label={"Seu número de celular para contato*"}
                fullWidth
                error={form.touched.phone && !!form.errors.phone}
                helperText={form.touched.phone ? form.errors.phone : undefined}
                {...form.getFieldProps("phone")}
              />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={6}>
                <AreaSelect
                  error={form.touched.area && !!form.errors.area}
                  helperText={form.touched.area ? form.errors.area : undefined}
                  value={form.values.area}
                  onChange={handleAreaOnChange}
                  setSubareaField={setSubareaField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {(form.values.area.includes(AreaFields.OTHER.title) ||
                  subareaField) && (
                  <Box
                    sx={{ ml: { md: 4 }, mt: { xs: 2, md: 0 }, width: "100%" }}
                  >
                    {form.values.area.includes(AreaFields.OTHER.title) && (
                      <TextField
                        label={"Escreva a área*"}
                        fullWidth
                        error={
                          form.touched.other_area && !!form.errors.other_area
                        }
                        helperText={
                          form.touched.other_area
                            ? form.errors.other_area
                            : undefined
                        }
                        {...form.getFieldProps("other_area")}
                      />
                    )}
                    {subareaField && (
                      <SubareaSelect
                        error={form.touched.subarea && !!form.errors.subarea}
                        helperText={
                          form.touched.subarea ? form.errors.subarea : undefined
                        }
                        value={form.values.subarea}
                        onChange={(value) =>
                          form.setFieldValue("subarea", value)
                        }
                        subarea={AreaFields[subareaField].subarea || {}}
                      />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={6}>
                <PositionSelect
                  error={form.touched.position && !!form.errors.position}
                  helperText={
                    form.touched.position ? form.errors.position : undefined
                  }
                  value={form.values.position}
                  onChange={handlePositionOnChange}
                  disabled={isPositionDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {form.values.position.includes(EnumContactJob.OTHER) && (
                  <TextField
                    sx={{ ml: { md: 4 }, mt: { xs: 2, md: 0 } }}
                    label={"Escreva o cargo*"}
                    fullWidth
                    error={
                      form.touched.other_position &&
                      !!form.errors.other_position
                    }
                    helperText={
                      form.touched.other_position
                        ? form.errors.other_position
                        : undefined
                    }
                    disabled={isPositionDisabled}
                    {...form.getFieldProps("other_position")}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography sx={{ color: "medium.dark" }}>
                Ao continuar, esteja ciente da{" "}
                <Link
                  target="_blank"
                  sx={{
                    color: "medium.dark",
                    textDecorationColor: "#767676",
                  }}
                  href="https://www.caju.com.br/privacidade"
                >
                  política de privacidade
                </Link>{" "}
                da Caju.
              </Typography>
            </Grid>
          </Grid>
          <NextButton type="submit" />
        </form>
      </>
    </FormWrapper>
  );
};
