export enum EnumGTMEvents {
  CONTACT_INFO__VIEW = "001/002-002.000:000;002",
  FORM_HEADER_CAJU_LOGO_CAJU__CLICK = "001/002-003.002:002;001",
  FORM_HEADER_HEADER_LINK_HELP_PAGE__CLICK = "001/002-003.003:003;001",
  CONTACT_INFO_FORM_BTN_CONTINUE__CLICK = "001/002-002.004:004;001",
  COMPANY_INFO__VIEW = "001/002-005.000:000;002",
  FORM_BODY_BACKWARD_BTN_BACK__CLICK = "001/002-004.005:005;001",
  COMPANY_INFO_FORM_BTN_CONTINUE__CLICK = "001/002-005.004:004;001",
  PRODUCT_INFO__VIEW = "001/002-006.000:000;002",
  PRODUCT_INFO_FORM_BTN_CONTINUE__CLICK = "001/002-006.004:004;001",
  PRODUCT_INFO_PRODUCT_INFO_TAG_TAG_DETAIL__CLICK = "001/002-006.006:006;001",
  INTEREST_INFO__VIEW = "001/002-007.000:000;002",
  MEETING_INFO__VIEW = "001/002-008.000:000;002",
  CONFIRMATION_INFO__VIEW = "001/002-009.000:000;002",
  CICLOS_INFO__VIEW = "001/002-010.000:000;002",
  CICLOS_INFO_ALTERNATIVE_BTN_MULTIBENEFICIOS__CLICK = "001/002-010.007:007;001",
  CICLOS_INFO_ALTERNATIVE_BTN_PREMIACOES__CLICK = "001/002-010.007:008;001",
  CICLOS_INFO_ALTERNATIVE_BTN_DESPESAS__CLICK = "001/002-010.007:009;001",
  CICLOS_INFO_ALTERNATIVE_BTN_CAJU_MAIS__CLICK = "001/002-010.007:017;001",
  INTEREST_INFO_INTEREST_OPTION_SIGNUP_NOW__CLICK = "001/002-007.008:011;001",
  INTEREST_INFO_INTEREST_OPTION_SCHEDUALED_MEETING__CLICK = "001/002-007.008:012;001",
  MEETING_INFO_DATE_OPTION_DATE__CLICK = "001/002-008.009:013;001",
  MEETING_INFO_SCHEDULING_CONFIRMATION_SUCCESS__DATA_SENT = "001/002-008.010:014;003",
  LAST_PAGE_BACKWARD_BTN_HOME_PAGE__CLICK = "001/002-011.005:015;001",
  CONFIRMATION_INFO_PRESENTATION_BTN_DOWNLOAD__CLICK = "001/002-009.011:016;001",
  ERROR_MSG_BACKWARD_BTN_HOME_PAGE__CLICK = "001/002-012.005:015;001",
  ERROR_MSG__VIEW = "001/002-012.000:000;002",
}
