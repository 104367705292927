import { createTheme } from "@mui/material";
import { MuiButtonOverrides } from "./muiButtonOverrides";
import { MuiCheckboxOverrides } from "./muiCheckboxOverrides";
import { MuiTooltipOverrides } from "./muiTooltipOverrides";
import { MuiSwitchOverrides } from "./muiSwitchOverrides";
import { MuiTypographyOverrides } from "./muiTypographyOverrides";
import { palleteOverrides } from "./palleteOverrides";
import { MuiRippleOverrides } from "./muiRippleOverrides";
import { MuiRadioOverrides } from "./muiRadioOverrides";
import { MuiAccordionOverrides } from "./muiAccordionOverrides";
import { MuiAccordionSummaryOverrides } from "./muiAccordionSummaryOverrides";
import { MuiAccordionDetailsOverrides } from "./muiAccordionDetailsOverrides";
import { MuiChipOverrides } from "./muiChipOverrides";
import { MuiIconButtonOverrides } from "./muiIconButtonOverrides";
import { MuiRatingOverrides } from "./muiRatingOverrides";
import { MuiAlertOverrides } from "./muiAlertOverrides";
import { MuiAvatarOverrides } from "./muiAvatarOverrides";
import { MuiFormControlOverrides } from "./muiFormControlOverrides";
import { MuiOutlinedInputOverrides } from "./muiOutlinedInputOverrides";
import { MuiPaperOverrides } from "./muiPaperOverrides";
import { MuiMenuItemOverrides } from "./muiMenuItemOverrides";
import { Tokens } from "../../tokens/tokens";
import { MuiListItemIconOverrides } from "./muiListItemIconOverrides";
import { MuiListItemOverrides } from "./muiListItemOverrides";
import { MuiCircularProgressOverrides } from "./muiCircularProgressOverrides";
import { MuiAppBarOverrides } from "./muiAppBarOverrides";
import { MuiStepConnectorOverrides } from "./muiStepConnectorOverrides";
import { MuiLinearProgressOverrides } from "./muiLinearProgressOverrides";

export const CajuMUItheme = createTheme({
  typography: {
    fontFamily: Tokens.fontFamily.marketing,
    allVariants: {
      color: Tokens.neutralColor.dark["200"],
    },
  },
  palette: palleteOverrides,
  components: {
    MuiAccordion: { styleOverrides: MuiAccordionOverrides },
    MuiAppBar: { styleOverrides: MuiAppBarOverrides },
    MuiAccordionDetails: { styleOverrides: MuiAccordionDetailsOverrides },
    MuiAccordionSummary: { styleOverrides: MuiAccordionSummaryOverrides },
    MuiAlert: { styleOverrides: MuiAlertOverrides },
    MuiAvatar: { styleOverrides: MuiAvatarOverrides },
    MuiButton: { styleOverrides: MuiButtonOverrides },
    MuiCheckbox: { styleOverrides: MuiCheckboxOverrides },
    MuiChip: { styleOverrides: MuiChipOverrides },
    MuiFormControl: { styleOverrides: MuiFormControlOverrides },
    MuiIconButton: { styleOverrides: MuiIconButtonOverrides },
    MuiMenuItem: { styleOverrides: MuiMenuItemOverrides },
    MuiOutlinedInput: { styleOverrides: MuiOutlinedInputOverrides },
    MuiPaper: { styleOverrides: MuiPaperOverrides },
    MuiLinearProgress: { styleOverrides: MuiLinearProgressOverrides },
    MuiRadio: { styleOverrides: MuiRadioOverrides },
    MuiRating: { styleOverrides: MuiRatingOverrides },
    MuiSwitch: { styleOverrides: MuiSwitchOverrides },
    MuiTooltip: { styleOverrides: MuiTooltipOverrides },
    MuiTouchRipple: { styleOverrides: MuiRippleOverrides },
    MuiTypography: { styleOverrides: MuiTypographyOverrides },
    MuiListItemIcon: { styleOverrides: MuiListItemIconOverrides },
    MuiListItem: { styleOverrides: MuiListItemOverrides },
    MuiStepConnector: { styleOverrides: MuiStepConnectorOverrides },
    MuiCircularProgress: { styleOverrides: MuiCircularProgressOverrides },
  },
  shape: {
    borderRadius: parseInt(Tokens.borderRadius.medium),
  },
});
