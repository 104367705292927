import { Box } from "@mui/material";
import React from "react";
import { ModalWrapper } from "components/modalWrapper";
import WellhubLogo from "assets/images/logos/logo-wellhub.png";
import ConexaLogo from "assets/images/logos/logo-conexa.png";
import PsicologiaAtivaLogo from "assets/images/logos/logo-psicologiaativa.png";
import OptumLogo from "assets/images/logos/logo-optum.png";
import SatLogo from "assets/images/logos/logo-sat.png";

interface CajuMaisModalProps {
  open: boolean;
  onClose: () => void;
  isWellhubAvailable: boolean;
}

export const CajuMaisModal = (props: CajuMaisModalProps) => {
  return (
    <ModalWrapper
      title="O que é Caju Mais?"
      subtitle="Caju Mais facilita a integração de benefícios da empresa em uma única plataforma, com planos para garantir mais qualidade de vida para os colaboradores"
      open={props.open}
      handleClose={props.onClose}
      customWidth={1024}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "center",
          gap: 4,
          justifyContent: "center",
        }}
      >
        {props.isWellhubAvailable && (
          <img src={WellhubLogo} alt="Logotipo da Wellhub" height={33} />
        )}
        <img src={ConexaLogo} alt="Logotipo da Conexa Saúde" />
        <img
          src={PsicologiaAtivaLogo}
          alt="Logotipo da Conexa Psicologia Ativa"
        />
        <img src={OptumLogo} alt="Logotipo da Optum" />
        <img src={SatLogo} alt="Logotipo da SAT" />
      </Box>
    </ModalWrapper>
  );
};
