import { Box, Container, Typography } from "@mui/material";
import * as React from "react";
import { PartnerFormContext } from "../partnerFormContext/context";
import { ReactComponent as ConfirmacaoAgendamentoImage } from "assets/images/confirmacao_agendamento_icon.svg";
import { ReactComponent as ErrorIcon } from "assets/images/icons/error_icon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Tokens } from "configs";

export const Step4 = () => {
  const partnerFormContext = React.useContext(PartnerFormContext);

  const contextSuccess =
    !partnerFormContext.loading && !partnerFormContext.error;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 3,
        pb: 3,
      }}
      maxWidth="lg"
    >
      {contextSuccess && (
        <>
          <Box>
            <ConfirmacaoAgendamentoImage />
          </Box>
          <Typography
            variant="subtitle1"
            textAlign="center"
            maxWidth="sm"
            sx={{ fontSize: { md: 24 }, fontWeight: { md: 700 } }}
          >
            Agradecemos o interesse.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Em breve entraremos em contato.
          </Typography>
        </>
      )}
      {partnerFormContext.loading && (
        <>
          <CircularProgress
            size={80}
            thickness={4}
            sx={{
              color: Tokens.neutralColor.medium["600"],
              animationDuration: "3.5s",
              mb: 3,
            }}
          />
          <Typography variant="h6" textAlign="center" maxWidth="sm">
            Quase lá...
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Aguarde, estamos enviando seus dados.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Não feche essa janela até receber a confirmação.
          </Typography>
        </>
      )}
      {partnerFormContext.error && (
        <>
          <ErrorIcon />
          <Typography variant="h6" textAlign="center" maxWidth="sm">
            Algo deu errado
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Desculpe, não conseguimos salvar seus dados.
          </Typography>
          <Typography variant="body1" textAlign="center" maxWidth="sm">
            Por favor, retorne e tente novamente mais tarde.
          </Typography>
        </>
      )}
    </Container>
  );
};
