import { ChipClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiChipOverrides: Partial<OverridesStyleRules<ChipClassKey, {}>> =
  {
    root: {
      borderRadius: Tokens.borderRadius.small,
      fontSize: Tokens.fontSize.xs,
      fontWeight: 500,
      backgroundColor: Tokens.neutralColor.light["800"],
      flexDirection: "row-reverse",
      padding: "2px 8px",
    },
    label: {
      padding: 0,
    },
    sizeSmall: {
      fontSize: Tokens.fontSize.xxs,
    },
    icon: {
      margin: 0,
      marginLeft: 8,
      fontSize: 14,
    },
    colorPrimary: {
      backgroundColor: Tokens.interfaceColor.primary.light,
      color: Tokens.interfaceColor.primary.dark,

      "& .MuiChip-deleteIcon": {
        color: Tokens.interfaceColor.primary.dark,
      },

      "& .MuiChip-deleteIcon:hover": {
        color: Tokens.interfaceColor.primary.pure,
      },
    },
  };
