import { googleAnalyticsAdapter } from "./helpers";
import { IPartnerLeadDataDto } from "./interfaces/iPartnerLeadDataDto";
import { IUpdateLeadDataDto } from "./interfaces/iUpdateLeadDataDto";
import { CajuClient } from "./client";

export class LeadService {
  static async postOpportunity(data: IUpdateLeadDataDto | IPartnerLeadDataDto) {
    const url = `/core/v1/seller/opportunity`

    const response = await CajuClient.post(url, googleAnalyticsAdapter(data))

    return response.data
  }

  static async putExpectedLead(data: IUpdateLeadDataDto) {
    const url = `/core/v1/seller/expected_lead`

    const response = await CajuClient.put(url, googleAnalyticsAdapter(data))

    return response.data
  }

  static async saveLead(data: IUpdateLeadDataDto) {
    const url = `/core/v1/seller/lead`

    const response = await CajuClient.patch(url, googleAnalyticsAdapter(data))

    return response.data
  }
}