import { IUpdateLeadDataDto } from "services/caju/interfaces/iUpdateLeadDataDto";
import { IStep1FormValues } from "../useStep1Form/iStep1FormValues";
import { EnumContactJob } from "services/caju/enums/enumContactJob";
import { AreaFields } from "services/caju/enums/enumArea";

const handleAreaValue = (values: IStep1FormValues) => {
  if(values.area.includes(AreaFields.OTHER.title)) return values.other_area
  
  if(values.subarea) return `${values.area} - ${values.subarea}`

  return values.area
}

export function getDtoFromStep1FormValues(
  leadId: string,
  values: IStep1FormValues
) {
  const result: IUpdateLeadDataDto = {
    externalId: leadId,

    lastName: values.name,
    email: values.email,
    mobilePhone: values.phone,
    contactJob: values.position.includes(EnumContactJob.OTHER) ? values.other_position : values.position,
    area: handleAreaValue(values),
    utmSource: values.utm_data?.utm_source,
    utmMedium: values.utm_data?.utm_medium,
    utmCampaign: values.utm_data?.utm_campaign,
    utmTerm: values.utm_data?.utm_term,
    utmContent: values.utm_data?.utm_content,
    isLead: false,
    step: "1",

    leadSource: "Website",

    channel: "Inbound",
  };

  return result;
}
