import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { EnumPartnerFormEvents } from "services/tagManager/enums/enumPartnerFormEvents";

export const handleBackToWebsiteBtn = (
  url: string,
  eventName: EnumGTMEvents | EnumPartnerFormEvents,
  newPage: boolean = false
) => {
  TagManagerService.push(eventName);
  window.open(url, newPage ? "_blank" : "_self", "noopener noreferrer");
};

export function isValidCep(str: string) {
  return /^\d{2}\.?\d{3}-\d{3}$/.test(str);
}
