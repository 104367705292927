import { Alert, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormWrapper } from "../../../components/formWrapper";
import { NextButton } from "../../../components/nextButton";
import { useStep2Form } from "./useStep2Form";
import { IStep2FormValues } from "./useStep2Form/iStep2FormValues";
import { TagManagerService } from "services/tagManager";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { formatterEmployeeCountTier } from "services/tagManager/utils";
import { StateUfSelect } from "./stateUFSelect";
import { WhereMeetCaju } from "./whereMeetCajuSelect";
import { QueuedUpdateContext } from "../queuedUpdateContext/context";
import { IStep1FormValues } from "../step1/useStep1Form/iStep1FormValues";
import { getDtoFromStep2FormValues } from "./useStep2Form/helper";
import { LeadService } from "services/caju";

export interface Step2Props {
  name?: string;
  onGoNext: (values: IStep2FormValues) => void;
  step1Values?: IStep1FormValues;
}

export const Step2 = (props: Step2Props) => {
  const [isBigAccount, setIsBigAccount] = useState<boolean>(false);
  const queueContext = React.useContext(QueuedUpdateContext);

  const form = useStep2Form({
    onSubmit: (values) => {
      values.expectedLeadId = queueContext.id
      handleSubmitEvent(values);
      props.onGoNext(values);
    },
  });

  const handleSubmitEvent = (values: IStep2FormValues) => {
    let data = {
      ...getDtoFromStep2FormValues(props.step1Values?.email || "", values),
    };
    queueContext.push(data, LeadService.putExpectedLead);

    TagManagerService.push(
      EnumGTMEvents.COMPANY_INFO_FORM_BTN_CONTINUE__CLICK,
      {
        employee_count: formatterEmployeeCountTier(form.values.employeesNumber),
      }
    );
  };

  useEffect(() => {
    setIsBigAccount(Number(form.values.employeesNumber) >= 1000);
  }, [form.values.employeesNumber]);

  const getFirstName = (name?: string) => name?.split(" ")[0];

  return (
    <FormWrapper>
      <>
        <Grid item xs={12} md={6} sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{ "&:first-letter": { textTransform: "capitalize" } }}
          >
            {getFirstName(props.name)}, me conta um pouco sobre a sua empresa?
          </Typography>
        </Grid>
        <form data-cy="step2-form" onSubmit={form.handleSubmit}>
          <Grid container gap={{ xs: 2, md: 4 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label={"Nome da empresa*"}
                fullWidth
                error={form.touched.company && !!form.errors.company}
                helperText={
                  form.touched.company ? form.errors.company : undefined
                }
                {...form.getFieldProps("company")}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={{ xs: 1, md: 4 }}
              position={"relative"}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Número total de colaboradores*"}
                  fullWidth
                  inputProps={{ min: 1 }}
                  type="number"
                  error={
                    form.touched.employeesNumber &&
                    !!form.errors.employeesNumber
                  }
                  helperText={
                    form.touched.employeesNumber
                      ? form.errors.employeesNumber
                      : undefined
                  }
                  {...form.getFieldProps("employeesNumber")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                position={{ xs: "initial", md: "absolute" }}
                right={{ md: -24 }}
              >
                <Alert severity="info" variant="outlined">
                  Para um melhor direcionamento preencha o número total de
                  colaboradores que trabalham hoje na empresa, e não apenas os
                  que utilizariam a solução
                </Alert>
              </Grid>
            </Grid>
            <Grid
              item
              container
              gap={{ xs: 2, md: 4 }}
              display={isBigAccount ? "flex" : "none"}
            >
              <Grid
                item
                container
                xs={12}
                md={6}
                gap={{ xs: 1, md: 4 }}
                wrap="nowrap"
              >
                <Grid item xs={12} md={7}>
                  <TextField
                    label="CEP da empresa"
                    fullWidth
                    error={form.touched.cep && !!form.errors.cep}
                    helperText={form.touched.cep ? form.errors.cep : undefined}
                    {...form.getFieldProps("cep")}
                    onChange={form.handleCepChange}
                    disabled={form.isComplementLoading}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <StateUfSelect
                    error={form.touched.uf && !!form.errors.uf}
                    helperText={form.touched.uf ? form.errors.uf : undefined}
                    value={form.values.uf}
                    onChange={(value) => form.setFieldValue("uf", value)}
                    disabled={form.isComplementLoading || form.isUfDisabled}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Site da empresa"}
                  fullWidth
                  error={form.touched.website && !!form.errors.website}
                  helperText={
                    form.touched.website ? form.errors.website : undefined
                  }
                  {...form.getFieldProps("website")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Linkedin da empresa"}
                  fullWidth
                  error={form.touched.linkedin && !!form.errors.linkedin}
                  helperText={
                    form.touched.linkedin ? form.errors.linkedin : undefined
                  }
                  {...form.getFieldProps("linkedin")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <WhereMeetCaju
                error={form.touched.whereMeetCaju && !!form.errors.whereMeetCaju}
                helperText={form.touched.whereMeetCaju ? form.errors.whereMeetCaju : undefined}
                value={form.values.whereMeetCaju}
                onChange={(value) => form.setFieldValue("whereMeetCaju", value)}
              />
            </Grid>
          </Grid>
          <NextButton type="submit" />
        </form>
      </>
    </FormWrapper>
  );
};
