import { Button, Typography } from "@mui/material";
import * as React from "react";

export interface PrevButtonProps {
  onClick: () => void;
}

export function PrevButton(props: PrevButtonProps) {
  return (
    <Button
      variant="text"
      onClick={props.onClick}
      sx={{ mt: { xs: 2, md: 0 } }}
    >
      <i
        className="uil uil-arrow-left"
        style={{
          fontSize: 24,
          display: "flex",
        }}
      />
      <Typography
        variant="subtitle2"
        sx={{
          display: { xs: "none", md: "block" },
          color: "medium.dark",
          "&:hover": {
            color: "dark.light",
          },
        }}
      >
        Voltar
      </Typography>
    </Button>
  );
}
