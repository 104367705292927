import { useState } from 'react';
import axios from 'axios';
import { EnumStatesUF } from 'services/caju/enums/enumStatesUF';

interface ZipCodeResponse {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: EnumStatesUF;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
    erro?: boolean;
}

interface GetComplementResult {
    uf?: EnumStatesUF;
}

const notFoundMessage = 'ZipCode Not Found'

function useZipCodeComplement() {
    const [loading, setLoading] = useState<boolean>(false)

    async function getComplement(zipCode: string) {
        try {
            setLoading(true)
            
            const zipCodeNumber = zipCode.replace(/\D/g, '')

            const { data } = await axios.get<ZipCodeResponse>( `https://viacep.com.br/ws/${zipCodeNumber}/json/`)

            if (data.erro) {
                throw new Error(notFoundMessage)
            }

            const result:GetComplementResult = {
                uf: data.uf,
            }
            return result
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log(error.status)
                console.error(error.response);
            } else {
                console.error(error);
            }
            throw error
        } finally {
            setLoading(false)
        }
    }

    return {
        getComplement,
        loading,
    }
}

export default useZipCodeComplement
