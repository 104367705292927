import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import React from "react";

interface ModalWrapperProps {
  children: React.ReactElement;
  title: string;
  subtitle: string;
  open: boolean;
  customWidth?: number;
  handleClose: () => void;
}

export const ModalWrapper = (props: ModalWrapperProps) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          p: { xs: 3, md: 8 },
          position: "relative",
          bgcolor: "light.light",
          borderRadius: 0.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: { xs: "100%", md: "auto" },
          width: { md: props.customWidth || 850 },
        }}
      >
        <IconButton
          aria-label="Fechar modal"
          sx={{
            color: "dark.dark",
            position: "absolute",
            top: 40,
            right: 40,
            display: { xs: "none", md: "block" },
          }}
          onClick={props.handleClose}
        >
          <i
            className="uil uil-times"
            style={{
              fontSize: 24,
            }}
          />
        </IconButton>
        <Box textAlign={"center"}>
          <Typography variant="subtitle1" mb={2}>
            {props.title}
          </Typography>
          <Typography variant="body1" color="dark.light" maxWidth="sm">
            {props.subtitle}
          </Typography>
        </Box>

        <Paper
          variant="outlined"
          sx={{
            borderRadius: 0.5,
            p: 3,
            mt: 4,
            borderColor: "medium.light",
            width: { xs: "100%", md: "auto" },
          }}
        >
          {props.children}
        </Paper>

        <Button
          variant="contained"
          size="large"
          sx={{
            display: { xs: "flex", md: "none" },
            position: "absolute",
            m: 3,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          onClick={props.handleClose}
          startIcon={
            <i
              className="uil uil-arrow-left"
              style={{
                fontSize: 24,
                display: "flex",
              }}
            />
          }
        >
          Voltar
        </Button>
      </Box>
    </Modal>
  );
};
