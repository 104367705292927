import { Button, Container, Typography } from "@mui/material";
import { EnumGTMEvents } from "services/tagManager/enums/enumGTMEvents";
import { handleBackToWebsiteBtn } from "utils";
import { ReactComponent as ErrorIcon } from "assets/images/icons/error_icon.svg";

export function ErrorBoundaryFallback() {
  return (
    <Container
      sx={{
        display: "flex",
        minHeight: "100dvh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
      maxWidth="lg"
    >
      <ErrorIcon />

      <Typography variant="h6" textAlign="center" maxWidth="sm" mt={2}>
        Algo deu errado
      </Typography>

      <Typography
        variant="body1"
        textAlign="center"
        maxWidth="sm"
        mt={2}
        mb={3}
      >
        Desculpe, não conseguimos salvar seus dados.
        <br />
        Por favor, retorne e tente novamente mais tarde.
      </Typography>

      <Button
        variant="outlined"
        onClick={() =>
          handleBackToWebsiteBtn(
            "https://www.caju.com.br",
            EnumGTMEvents.ERROR_MSG_BACKWARD_BTN_HOME_PAGE__CLICK
          )
        }
      >
        Voltar para o site
      </Button>
    </Container>
  );
}
