import { IPartnerLeadDataDto } from "services/caju/interfaces/iPartnerLeadDataDto";
import { IStep1FormValues } from "./iStep1FormValues";

export function getDtoFromStep1PartnerFormValues(values: IStep1FormValues) {
  const result: IPartnerLeadDataDto = {
    partnerName: values.name,
    mobile: values.phone,
    email: values.email,
  };

  return result;
}
