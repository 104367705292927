import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import * as React from "react";
import { StepLabelIcon } from "../StepLabelIcon";
import { QueuedUpdateContext } from "pages/home/queuedUpdateContext/context";

interface StepperContainerProps {
  isMassiveClient?: string | number | boolean;
}

export function StepperContainer(props: StepperContainerProps) {
  const queueContext = React.useContext(QueuedUpdateContext);

  return (
    <>
      <Stepper
        alternativeLabel
        sx={{
          mt: 8,
          mb: 5,
          width: "100%",
          display: { xs: "none", md: "flex" },
        }}
      >
        <Step>
          <StepLabel
            StepIconComponent={StepLabelIcon}
            error={queueContext.error ? true : false}
          >
            <Typography variant="subtitle2">Agendamento confirmado.</Typography>
            {!queueContext.error && (
              <Typography variant="body1" color="dark.light">
                Detalhes serão enviados por e-mail.
              </Typography>
            )}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon}>
            <Typography variant="subtitle2">
              {props.isMassiveClient ? "Webinar com Caju." : "Papo com Caju."}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon}>
            <Typography variant="subtitle2">Cadastro e ativação.</Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon}>
            <Typography variant="subtitle2">Tudo pronto para usar!</Typography>
          </StepLabel>
        </Step>
      </Stepper>

      <Stepper
        orientation="vertical"
        sx={{ display: { xs: "flex", md: "none" }, my: 5 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon} sx={{ p: 0 }}>
            <Typography variant="subtitle2">Agendamento confirmado.</Typography>
            <Typography variant="body1" color="dark.light">
              Detalhes serão enviados por e-mail.
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon} sx={{ p: 0 }}>
            <Typography variant="subtitle2">
              {props.isMassiveClient ? "Webinar com Caju." : "Papo com Caju."}
            </Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon} sx={{ p: 0 }}>
            <Typography variant="subtitle2">Cadastro e ativação.</Typography>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepLabelIcon} sx={{ p: 0 }}>
            <Typography variant="subtitle2">Tudo pronto para usar!</Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </>
  );
}
