import { ListItemIconClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiListItemOverrides: Partial<
  OverridesStyleRules<ListItemIconClassKey, {}>
> = {
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
};
