import { IUpdateLeadDataDto } from "services/caju/interfaces/iUpdateLeadDataDto";
import { IStep2FormValues } from "../useStep2Form/iStep2FormValues";

export function getDtoFromStep2FormValues(
  leadId: string,
  values: IStep2FormValues,
) {
  const result: IUpdateLeadDataDto = {
    externalId: leadId,
    expectedLeadId: values.expectedLeadId,
    isLead: false,
    step: "2",

    company: values.company,
    employeesNumber: values.employeesNumber,
    whereMetCaju: values.whereMeetCaju,

    postalCode: values.cep,
    companyLinkedin: values.linkedin,
    website: values.website,
    stateCode: values.uf,
    countryCode: 'BR'
  };

  return result;
}
