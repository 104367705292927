import { RadioClassKey } from "@mui/material";
import { Tokens } from "../../tokens/tokens";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiRadioOverrides: Partial<
  OverridesStyleRules<RadioClassKey, {}>
> = {
  root: {
    transition:
      "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms," +
      "border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
  },
  colorPrimary: {
    "&:active": {
      color: Tokens.interfaceColor.primary.dark,
    },
  },
};
