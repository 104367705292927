import * as React from "react";
import { EnumStatesUF } from "services/caju/enums/enumStatesUF";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

export interface StateUfSelectProps {
  error?: boolean;
  helperText?: string;
  value: EnumStatesUF | "";
  onChange: (value: EnumStatesUF | "") => void;
  disabled: boolean;
}

export function StateUfSelect(props: StateUfSelectProps) {
  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel id="uf-select-label">UF*</InputLabel>
      <Select
        labelId="uf-select-label"
        id="uf-select"
        value={props.value}
        label="UF*"
        onChange={(evt) => {
          props.onChange(evt.target.value as EnumStatesUF);
        }}
        disabled={props.disabled}
      >
        <MenuItem value={""}>Selecione um valor</MenuItem>
        {Object.values(EnumStatesUF).map((uf) => (
            <MenuItem value={uf} key={uf}>
                {uf}
            </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
