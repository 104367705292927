import { PaperClassKey } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiPaperOverrides: Partial<
  OverridesStyleRules<PaperClassKey, {}>
> = {
  root: {
    marginTop: 4,
  },
};
