import { useFormik } from "formik";
import { IStep3FormValues } from "./iStep3FormValues";
import { validations } from "./validations";

export interface UseStep3FormProps {
  onSubmit: (values: IStep3FormValues) => void;
}

export function useStep3Form(props: UseStep3FormProps) {
  const form = useFormik<IStep3FormValues>({
    initialValues: {
      products: [],
    },
    validationSchema: validations,
    onSubmit: props.onSubmit,
  });

  return {
    ...form,
  };
}
